import { SET_ERRORS, SET_LOADING, SET_TOKEN, SET_PROPERTIES } from '../../utils/constants';


const initialState = {
  errors: [],
  isLoading: false,
  token: '',
  properties: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN: return { ...state, token: action.token };
    case SET_ERRORS: return { ...state, errors: action.errors };
    case SET_LOADING: return { ...state, isLoading: action.isLoading };
    case SET_PROPERTIES: return { ...state, properties: action.properties };
    default: return state;
  }
};
