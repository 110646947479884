import React, {Component} from "react";
import {Link} from "react-router-dom";

import * as Icons from "react-feather";

import opicLogo from "../../resources/opic-logo-1.png";

export default class HeaderPageHeader extends Component {
  defaultState = {
    mobileTab: false,
    CancelTab: true,
  };

  state = {...this.defaultState};

  CancelTabClick = (e) => {
    e.preventDefault();
    this.setState({
      mobileTab: false,
      CancelTab: true,
    });
  };

  mobileTabClick = (e) => {
    e.preventDefault();
    this.setState({
      mobileTab: true,
      CancelTab: false,
    });
  };

  render() {
    return (
      <div
        data-collapse='medium'
        data-animation='default'
        data-duration='400'
        className='nav-bar o-nav'
      >
        <div className='nav-wrapper o-container'>
          <Link
            to='/'
            id=''
            aria-current='page'
            className='logo-wrapper o-nav-brand o--current'
          >
            <img
              className='footer-logo'
              src={opicLogo}
              width='71'
              alt='Opic Logo'
            />
          </Link>
          <nav role='navigation' id='' className='nav-menu-holder o-nav-menu'>
            <Link to='/' aria-current='page' className='nav-items o-nav-link'>
              Home
            </Link>

            <Link
              className='nav-items o-nav-link dropdown-toggle'
              id='navbarDropdown'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              About Us
              <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
                <Link className='dropdown-item' to='/company-profile'>
                  Company Profile
                </Link>
                <div className='dropdown-divider'></div>
                <Link className='dropdown-item' to='/about-opic/board-members'>
                  Board Members
                </Link>
                <div className='dropdown-divider'></div>
                <Link
                  className='dropdown-item'
                  to='/about-opic/executive-management'
                >
                  Executive Management
                </Link>
              </div>
            </Link>

            <Link to='/find-property' className='nav-items o-nav-link'>
              Our Projects
            </Link>

            <Link to='/home-purchase' className='nav-items o-nav-link'>
              Home Purchase
            </Link>
            <Link to='/contact-us' className='nav-items o-nav-link'>
              Contact Us
            </Link>
          </nav>

          <div id='' className='nav-menu-button o-nav-button'>
            <div className='nav-icon'>
              <span className='nav-toggle-icon' onClick={this.mobileTabClick}>
                
              </span>
            </div>
          </div>
          <Link
            id=''
            to='tel:+2348080825059'
            className='site-button header-action o-button'
          >
            <span className='btn-icon'></span>+2348112892020
          </Link>
        </div>

        <div
          className='mobile-menu-wrapper'
          style={{display: this.state.mobileTab ? "" : "none"}}
        >
          <div className='mobile-menu-header-wrapper'>
            <h4>Main Navigation</h4>

            <div className='mobile-menu-header_cancel_button'>
              <Icons.X size='20px' onClick={this.CancelTabClick} />
            </div>
          </div>

          <div className='mobile-menu-bottom-wrapper'>
            <ul className='mobile-menu-bottom-list'>
              <li>
                <Link to='/'>Home</Link>
              </li>
              <li>
                <Link to='/company-profile'>About Us</Link>
              </li>

              <li>
                <Link to='/find-property'>Our Projects</Link>
              </li>

              {/* <li>
                <Link to='/'>Opic Advantage</Link>
              </li> */}

              <li>
                <Link to='/home-purchase'>Home Purchase</Link>
              </li>

              <li>
                <Link to='/'>Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
