import React, {Component} from "react";
import {Helmet} from "react-helmet";

import * as Icons from "react-feather";

import FooterPage from "../../components/FooterPage";
import sampleLogo from "../../resources/sample-team-member.png";

import "./SiteMap.css";

class BoardMembers extends Component {
  render() {
    return (
      <div className='container-fluid p-0'>
        <Helmet>
          <meta charset='utf-8' />
          <meta name='viewport' content='width=device-width, initial-scale=1' />
          <title>
            Board of Directors | OPIC - Expanding the frontiers of Ogun State
          </title>
          <link rel='canonical' href='/' />
        </Helmet>

        <section id='company-team'>
          <div className='container'>
            <div className='company-team-wrapper'>
              <h2>Board of Directors</h2>
              <div className='row'>
                <div className='col-md-3'>
                  <div className='team-container'>
                    <img alt='Team Member' class='team-img' src={sampleLogo} />
                    <div className='team-info'>
                      <h3 class='team-name'>Maurice Okolie</h3>
                      <p class='team-position mb-1'>Founder &amp; CEO</p>
                    </div>
                    <div className='team-social-media'>
                      <Icons.Linkedin
                        size='25px'
                        color='#b2b2b2'
                        className='mr-2'
                      />
                      <Icons.Twitter size='25px' color='#b2b2b2' />
                    </div>
                  </div>
                </div>

                <div className='col-md-3'>
                  <div className='team-container'>
                    <img alt='Team Member' class='team-img' src={sampleLogo} />
                    <div className='team-info'>
                      <h3 class='team-name'>Maurice Okolie</h3>
                      <p class='team-position mb-1'>Founder &amp; CEO</p>
                    </div>
                    <div className='team-social-media'>
                      <Icons.Linkedin
                        size='25px'
                        color='#b2b2b2'
                        className='mr-2'
                      />
                      <Icons.Twitter size='25px' color='#b2b2b2' />
                    </div>
                  </div>
                </div>

                <div className='col-md-3'>
                  <div className='team-container'>
                    <img alt='Team Member' class='team-img' src={sampleLogo} />
                    <div className='team-info'>
                      <h3 class='team-name'>Maurice Okolie</h3>
                      <p class='team-position mb-1'>Founder &amp; CEO</p>
                    </div>
                    <div className='team-social-media'>
                      <Icons.Linkedin
                        size='25px'
                        color='#b2b2b2'
                        className='mr-2'
                      />
                      <Icons.Twitter size='25px' color='#b2b2b2' />
                    </div>
                  </div>
                </div>

                <div className='col-md-3'>
                  <div className='team-container'>
                    <img alt='Team Member' class='team-img' src={sampleLogo} />
                    <div className='team-info'>
                      <h3 class='team-name'>Maurice Okolie</h3>
                      <p class='team-position mb-1'>Founder &amp; CEO</p>
                    </div>
                    <div className='team-social-media'>
                      <Icons.Linkedin
                        size='25px'
                        color='#b2b2b2'
                        className='mr-2'
                      />
                      <Icons.Twitter size='25px' color='#b2b2b2' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <FooterPage />
      </div>
    );
  }
}

export default BoardMembers;
