import React from 'react';
import { requireFieldErrorMsg } from './errorMessageUtils';

export const validateForm = (event) => {
  event.preventDefault();
  const { target } = event;
  target.classList.add('was-validated');
  return target.querySelector('input:invalid');
};

export const getInputErrorElement = (errorMsg) => (
  <div className="invalid-feedback">{errorMsg || requireFieldErrorMsg}</div>
);

export const getReplaceErrorElement = (errorMsg = requireFieldErrorMsg) => (
  <div className="error-wrapper">
    <input type="text" pattern='\d+' value='bola' onChange={() => {}} required style={{ display: 'none' }} />
    {getInputErrorElement(errorMsg)}
  </div>
);
