import React, {Component} from "react";
import {Helmet} from "react-helmet";
import FooterPage from "../../components/FooterPage";
import * as Icons from "react-feather";

export default class ContactUsPage extends Component {
  render() {
    return (
      <div className='container-fluid p-0'>
        <Helmet>
          <meta charset='utf-8' />
          <meta name='viewport' content='width=device-width, initial-scale=1' />
          <title>
            Contact Us | OPIC - Expanding the frontiers of Ogun State
          </title>
          <link rel='canonical' href='https://opicgroup.com/about' />
        </Helmet>
        <section id='opic-contact-us'>
          <div className='container'>
            <div className='opic-contact-us-wrapper'>
              <div className='row'>
                <div className='col-12 col-lg-8 offset-lg-2 col-sm-12'>
                  <h2>Get in touch!</h2>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </p>
                </div>
              </div>
              <div className='row'>
                <div className='col-12 col-lg-4 col-sm-12'>
                  <div className='opic-contact-locations'>
                    <h5>Sales &amp; Enquiries</h5>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever.
                    </p>
                  </div>
                </div>
                <div className='col-12 col-lg-4 col-sm-12'>
                  <div className='opic-contact-locations'>
                    <h5>PR &amp; Press</h5>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever.
                    </p>
                  </div>
                </div>
                <div className='col-12 col-lg-4 col-sm-12'>
                  <div className='opic-contact-locations'>
                    <h5>Careers &amp; Partnership</h5>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id='opic-map-locations'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 col-md-8 offset-md-2'>
                <div className='opic-map-locations-wrapper'>
                  <h2>Find your way here</h2>
                  <div className='opic-map-location-list'>
                    <div className='opic-map-address'>
                      <h5>OPIC HQ</h5>
                      <address>
                        OPIC Circle, Oke-Ilewo, Abeokuta,
                        <br /> Ogun State
                      </address>
                      <a href=''>
                        <Icons.ArrowRight
                          size='15px'
                          color='#136b3d'
                          className='mr-1'
                        />
                        View on Google Maps
                      </a>
                    </div>
                    {/* <div className='divider'></div> */}
                    <div className='opic-map-address'>
                      <h5>OPIC Ikeja Office</h5>
                      <address>
                        Mobolaji Bank Anthony way, beside Sheraton, Hotel,
                        Ikeja, <br />
                        Lagos State.
                      </address>
                      <a href=''>
                        <Icons.ArrowRight
                          size='15px'
                          color='#136b3d'
                          className='mr-1'
                        />
                        View on Google Maps
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <FooterPage />
      </div>
    );
  }
}
