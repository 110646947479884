import React, {Component} from "react";
import "../../modules/sitemaps/WidgetCSS.css";

export default class FinancePlusWidget extends Component {
  render() {
    return (
      <div id='finance-plus-widget-affordability-eligibility'>
        <div className='container'>
          <div className='finance-plus-widget-affordability-eligibility-wrapper'>
            <div className='finance-plus-widget-affordability-eligibility-form'>
              <div className='row'>
                <div className='col-md-12 affordability-eligibility-form-summary'>
                  <div className='finance-plus-widget-affordability-eligibility-form-summary single-page'>
                    <div className='finance-plus-widget-affordability-eligibility-summary-content'>
                      <div className='row'>
                        <div className='col-md-8 offset-md-2'>
                          <div className='finance-plus-widget-affordability-eligibility-summary-content-bottom-text'>
                            <div className='summary-property-price-box'>
                              <div className='price-card-inner'>
                                <div className='price-from'>Prices from</div>
                                <h2 className='property-price'>
                                  &#8358; 30,000,000
                                </h2>
                              </div>
                              <div className='separator'></div>

                              <div className='price-card-inner'>
                                <div className='price-from'>
                                  Maximum loanable Amount
                                </div>
                                <h2 className='property-price'>
                                  &#8358; 30,000,000
                                </h2>
                              </div>

                              <div className='separator'></div>

                              <div className='price-card-inner'>
                                <div className='price-from'>
                                  Equity Contribution
                                </div>
                                <div className='form-row'>
                                  <div className='col-md-12 mt-2'>
                                    <div className='range-slider'>
                                      <input
                                        className='range-slider__range'
                                        type='range'
                                        value='100'
                                        min='0'
                                        max='500'
                                      />
                                      <div className='d-flex justify-content-between mt-2'>
                                        <div className='range-slider__range-min'>
                                          N30,000
                                        </div>
                                        <div className='range-slider__range-max'>
                                          N2,000,000
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='col-md-12 mt-2'>
                                    <input
                                      type='text'
                                      name='emi'
                                      className='form-control'
                                      value='1000000'
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className='property-mortage-highlight mt-3'>
                                <div className='price-card-inner'>
                                  <p className='property-mortgage-heading'>
                                    Buy on a mortgage
                                  </p>
                                  <div className='mortgage-wrapper'>
                                    <p className='property-mortage-value'>
                                      &#8358; 30,000
                                    </p>
                                    <div className='property-mortgage-freq'>
                                      /month
                                    </div>
                                  </div>
                                </div>
                                <div className='separator'></div>
                                <div className='price-card-inner'>
                                  <p className='property-repayment-heading'>
                                    Payback Over
                                  </p>
                                  <div className='repayment-wrapper'>
                                    <p className='property-repayment-period'>
                                      20
                                    </p>
                                    <div className='property-repayment-freq'>
                                      /Years
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <a className='side-bar-btn o-button text-white'>
                                Buy this property
                              </a>
                            </div>
                          </div>

                          <div className='text-center p-3'>
                            <p>&nbsp;</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-md-12 affordability-eligibility-form'>
                  <div className='finance-plus-widget-form'>
                    <div className='form-row'>
                      <div className='col-md-8 mt-2'>
                        <label>What is your Net monthly income?</label>
                      </div>

                      <div className='col-md-4 mt-2'>
                        <input
                          type='text'
                          name='emi'
                          className='form-control'
                          value='1000000'
                        />
                      </div>

                      <div className='col-md-12 mt-2'>
                        <div className='range-slider'>
                          <input
                            className='range-slider__range'
                            type='range'
                            value='100'
                            min='0'
                            max='500'
                          />
                          <div className='d-flex justify-content-between mt-2'>
                            <div className='range-slider__range-min'>
                              N30,000
                            </div>
                            <div className='range-slider__range-max'>
                              N2,000,000
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='form-row mt-4'>
                      <div className='col-12 col-md-12'>
                        <label>Do you have additional income?</label>
                      </div>

                      <div className='col-12 col-md-6 mt-2'>
                        <input
                          type='radio'
                          name='additional_income'
                          value='Yes'
                          id='radio-one'
                          className='form-radio'
                          checked
                        />
                        <label for='radio-one'>Yes</label>
                      </div>

                      <div className='col-12 col-md-6 mt-2'>
                        <input
                          type='radio'
                          name='additional_income'
                          value='No'
                          id='radio-two'
                          className='form-radio'
                        />
                        <label for='radio-two'>No</label>
                      </div>
                    </div>

                    <div className='form-row mt-4'>
                      <div className='col-12 col-md-12'>
                        <label>Do you have other loan obligations?</label>
                      </div>

                      <div className='col-12 col-md-6 mt-2'>
                        <input
                          type='radio'
                          name='loan_obligations'
                          value='Yes'
                          id='radio-one'
                          className='form-radio'
                          checked
                        />
                        <label for='radio-one'>Yes</label>
                      </div>

                      <div className='col-12 col-md-6 mt-2'>
                        <input
                          type='radio'
                          name='loan_obligations'
                          value='No'
                          id='radio-two'
                          className='form-radio'
                        />
                        <label for='radio-two'>No</label>
                      </div>
                    </div>

                    <div className='form-row mt-4'>
                      <div className='col-md-8 mt-2'>
                        <label>Date of Birth?</label>
                      </div>

                      <div className='col-md-4 mt-2'>
                        <input
                          type='text'
                          name='age'
                          className='form-control'
                          value='20 Year(s)'
                        />
                      </div>

                      <div className='col-md-12 mt-2'>
                        <div className='range-slider'>
                          <input
                            className='range-slider__range'
                            type='range'
                            value='100'
                            min='0'
                            max='500'
                          />
                          <div className='d-flex justify-content-between mt-2'>
                            <div className='range-slider__range-min'>20</div>
                            <div className='range-slider__range-max'>50</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='form-row mt-4'>
                      <div className='col-md-8 mt-2'>
                        <label>How long do you want this loan for?</label>
                      </div>

                      <div className='col-md-4 mt-2'>
                        <input
                          type='text'
                          name='age'
                          className='form-control'
                          value='20 Year(s)'
                        />
                      </div>

                      <div className='col-md-12 mt-2'>
                        <div className='range-slider'>
                          <input
                            className='range-slider__range'
                            type='range'
                            value='100'
                            min='0'
                            max='500'
                          />
                          <div className='d-flex justify-content-between mt-2'>
                            <div className='range-slider__range-min'>20</div>
                            <div className='range-slider__range-max'>50</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='form-row mt-4'>
                      <div className='col-md-8 mt-2'>
                        <label>Loan interest rate?</label>
                      </div>

                      <div className='col-md-4 mt-2'>
                        <input
                          type='text'
                          name='tenure'
                          className='form-control'
                          value='20 Year(s)'
                        />
                      </div>

                      <div className='col-md-12 mt-2'>
                        <div className='range-slider'>
                          <input
                            className='range-slider__range'
                            type='range'
                            value='100'
                            min='0'
                            max='500'
                          />
                          <div className='d-flex justify-content-between mt-2'>
                            <div className='range-slider__range-min'>20</div>
                            <div className='range-slider__range-max'>50</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-md-12 affordability-eligibility-mobile-summary'>
                  <div className='container px-0'>
                    <div className='affordability-eligibility-mobile-summary-wrapper'>
                      <div className='loan-amount-equity-contribution'>
                        <div className='loan-amount'>
                          <p>Loanable Amount</p>
                          <h2>N30,000,000</h2>
                        </div>
                        <div className='divider'></div>
                        <div className='equity-contribution d-none d-sm-block'>
                          <form>
                            <div className='form-group'>
                              <label>Equity Contributions</label>
                              <input
                                type='number'
                                placeholder=''
                                value='30,000,000'
                                name='equity_contribution'
                              />
                            </div>
                          </form>
                        </div>
                        <div className='divider'></div>
                        <div className='loan-amount'>
                          <p>Buy on a mortgage</p>
                          <h2>
                            N30,000 <sup>/monthly</sup>
                          </h2>
                        </div>
                      </div>

                      <div className='loan-amount-equity-contribution d-lg-none d-xl-block d-md-none'>
                        <div className='equity-contribution'>
                          <form>
                            <div className='form-group'>
                              <label>Equity Contribution</label>
                              <input
                                type='number'
                                placeholder=''
                                value='30,000,000'
                                name='equity_contribution'
                              />
                            </div>
                          </form>
                        </div>
                      </div>

                      <div className='loan-amount-equity-contribution'>
                        <div className='loan-amount'>
                          <p>Prices From</p>
                          <h2>N30,000,000</h2>
                        </div>
                        <div className='divider'></div>
                        <div className='loan-amount property-title'>
                          <p>Property title</p>
                          <h4>Certificate Of Occupancy</h4>
                        </div>
                        <div className='divider'></div>
                        <div className='loan-amount'>
                          <p>Payback Over</p>
                          <h4>
                            20 <sup>/Years</sup>
                          </h4>
                        </div>
                      </div>
                      <div className='loan-amount-equity-contribution-cta'>
                        <a className='buy-this-property text-white'>
                          Buy this property
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
