import React from "react";
import { formatMoneyValue } from "../../utils/stringUtils";

const mapper = {
  6: 'mln', 9: 'bln', 12: 'tln'
};

const getMapping = (unit, exponent) => {
  let chosenExponent;
  if (exponent >= 12) chosenExponent = 12;
  else if (exponent >= 9) chosenExponent = 9;
  else if (exponent >= 6) chosenExponent = 6;

  const rem = exponent - chosenExponent;
  const mappedUnit = mapper[chosenExponent];
  const value = unit + '0'.repeat(rem > 0 ? rem : 0);
  return { value, mappedUnit };
};

const ExponentialYTick = ({ x, y, stroke, payload }) => {
  let [unit, exponent] = parseInt(payload.value, 10).toExponential().split('e+');
  const { value, mappedUnit } = getMapping(unit, parseInt(exponent, 10));
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={-26}
        y={0}
        dy={5}
        textAnchor="end"
        fill="#666"
      >
        {value}
      </text>

      <text
        x={-2}
        y={0}
        dy={5}
        textAnchor="end"
        fill="#666"
        transform="scale(0.9)"
      >
        {mappedUnit}
      </text>
    </g>
  );
};

export default ExponentialYTick;
