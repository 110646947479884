import React, {Component} from "react";
class AmenityList extends Component {
  renderAmenities = () => {
    const mapAmenity =
      this.props.amenities === undefined ? (
        <p>No entries found</p>
      ) : (
        this.props.amenities.map((amen, i) => (
          <div
            key={amen.id}
            id={i}
            className='property-info-icon-wrapper amenities'
          >
            <div
              className='info-icon img'
              style={{backgroundImage: `url(${amen.amenity_icon})`}}
            ></div>
            <div className='info-icon-name'>{amen.amenity_name}</div>
          </div>
        ))
      );
    return mapAmenity;
  };

  render() {
    return <div className='amenities-wrapper'>{this.renderAmenities()}</div>;
  }
}
export default AmenityList;
