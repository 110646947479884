import React, {Component} from "react";
import axios from "axios";

class InstallmentPlan extends Component {
  defaultState = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    typeid: "",
    propertyid: "",
    validationMessage: null,
    color: "red"
  };
  state = {...this.defaultState};

  handleFirstNameChange = e => {
    let value = e.target.value;
    this.setState({
      firstname: value.trim()
    });
  };

  handleLastNameChange = e => {
    let value = e.target.value;
    this.setState({
      lastname: value.trim()
    });
  };

  handlePhoneNumberChange = e => {
    let value = e.target.value;
    this.setState({
      phone: value.trim()
    });
  };

  handleEmailAddressChange = e => {
    let value = e.target.value;
    this.setState({
      email: value.trim()
    });
  };

  installmentPayment = e => {
    e.preventDefault();

    this.setState({
      personalInfoMessage: "",
      color: "red"
    });

    if (!this.state.firstname) {
      this.setState({
        validationMessage: "Kindly provide your first name",
        color: "red"
      });
      return;
    }

    if (!this.state.lastname) {
      this.setState({
        validationMessage: "kindly provide your last name",
        color: "red"
      });
      return;
    }

    if (!this.state.phone) {
      this.setState({
        validationMessage: "kindly provide a valid phone number",
        color: "red"
      });
      return;
    }

    const emailRegex = /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/;

    if (!emailRegex.test(this.state.email)) {
      this.setState({
        validationMessage: "kindly your provide a valid email address",
        color: "red"
      });
      return;
    }

    const data = {
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      phone: this.state.phone,
      email: this.state.email,
      typeid: 2,
      propertyid: this.props.propertyID
    };

    axios
      .post(
        "https://app.newhomes.ng/api/developerapp/opic/add-property-purchase",
        data
      )
      .then(
        response => {
          this.setState({
            firstname: null,
            lastname: null,
            phone: null,
            email: null,
            validationMessage: "Thank you! Your request has been received",
            color: "green"
          });
          console.log(response);
        },
        error => {
          this.setState({
            validationMessage:
              "Oops! Something went wrong while submitting the form.",
            color: "red"
          });
          console.log(error);
        }
      );
  };
  render() {
    return (
      <div
        className='installment-form-wrapper o-form'
        style={{
          display: this.props.installmentPlan ? "" : "none"
        }}
      >
        <p className='m-4'>
          Payment can be spread over a period of 3 to 20 years. Payment plans
          available are;
        </p>
        <ul>
          <li>
            <strong> - OPIC Advantage: </strong> This is a payment plan that
            allows for payment within a maximum of 3 years at a 5% premium.
          </li>
          <li>
            <strong>- OPIC Advantage+:</strong> This payment plan is in
            conjuction with mortgage banking partners and allows a qualified
            buyer pay over a period of 5 years at attractive terms.
          </li>
          <li>
            <strong>- OPIC Mortgage:</strong> This is a payment plan carried out
            in partnership with mortgage banking partners and allows a qualified
            buyer pay over a 20-year period on attractive terms
          </li>
        </ul>
        <form
          id='wf-form-Installment-Purchase'
          name='wf-form-Installment-Purchase'
          data-name='Installment Purchase'
          className='purchase-form mt-4'
          onSubmit={this.installmentPayment}
        >
          <div
            id='purchase-form-first-name'
            className='purchase-form-input-wrap'
          >
            <div className='purchase-form-input-label'>First name</div>
            <input
              type='text'
              className='purchase-form-input o-input'
              maxLength='100'
              name='First-Name'
              data-name='First Name'
              placeholder='First name'
              id='First-Name'
              onChange={this.handleFirstNameChange}
              value={this.state.firstname || ""}
            />
          </div>
          <div
            id='purchase-form-last-name'
            className='purchase-form-input-wrap'
          >
            <div className='purchase-form-input-label'>Last name</div>
            <input
              type='text'
              className='purchase-form-input o-input'
              maxLength='100'
              name='Last-Name'
              data-name='Last Name'
              placeholder='Last name'
              id='Last-Name'
              onChange={this.handleLastNameChange}
              value={this.state.lastname || ""}
            />
          </div>
          <div id='purchase-form-phone' className='purchase-form-input-wrap'>
            <div className='purchase-form-input-label'>Phone</div>
            <input
              type='number'
              className='purchase-form-input o-input'
              maxLength='15'
              name='Phone'
              data-name='Phone'
              placeholder='Phone'
              id='Phone'
              onChange={this.handlePhoneNumberChange}
              value={this.state.phone || ""}
            />
          </div>
          <div id='purchase-form-email' className='purchase-form-input-wrap'>
            <div className='purchase-form-input-label'>Email</div>
            <input
              type='email'
              className='purchase-form-input o-input'
              maxLength='256'
              name='Email'
              data-name='Email'
              placeholder='Email'
              id='Email'
              onChange={this.handleEmailAddressChange}
              value={this.state.email || ""}
            />
          </div>
          <input
            type='submit'
            value='Submit'
            data-wait='Please wait...'
            id='purchase-form-btn'
            className='site-button o-button'
          />
        </form>
        <div
          className='success-message o-form-done m-4'
          style={{
            backgroundColor: this.state.color,
            color: "#ffffff",
            display: this.state.validationMessage ? "block" : "none"
          }}
        >
          <div>{this.state.validationMessage || ""}</div>
        </div>
      </div>
    );
  }
}

export default InstallmentPlan;
