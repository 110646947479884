import React from 'react';

import './AdminProfile.css';
import ProfileUserDetailsForm from './ProfileUserDetailsForm';
import ProfilePasswordForm from './ProfilePasswordForm';


const AdminProfile = ({ history }) => {
  return (
    <div className="admin-profile-wrapper card px-3 py-4">
      <h3>Profile</h3>
      <div className='d-flex flex-wrap row'>
        <ProfileUserDetailsForm history={history} />
        <ProfilePasswordForm history={history} />
      </div>
    </div>
  );
}
 
export default AdminProfile;