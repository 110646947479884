import React, {Component} from "react";
import {Helmet} from "react-helmet";
import FooterPage from "../../components/FooterPage";
import "./SiteMap.css";

export default class FaqsPage extends Component {
  render() {
    return (
      <div className='container-fluid p-0'>
        <Helmet>
          <meta charset='utf-8' />
          <meta name='viewport' content='width=device-width, initial-scale=1' />
          <title>
            Frequently Asked Questions | OPIC - Expanding the frontiers of Ogun
            State
          </title>
          <link rel='canonical' href='https://opicgroup.com/about' />
        </Helmet>
        <section id='faqs-page'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 col-md-12'>
                <div className='faqs-page-sub-header-text'>
                  <h2>Frequently Asked Questions</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id='opic-faqs'>
          <div className='container'>
            <div className='opic-faqs-wrapper'>
              <ul>
                <li>
                  <input type='checkbox' />
                  <i></i>
                  <h2>
                    What is the process in the acquisition of OPIC property?
                  </h2>
                  <p>
                    <strong>Step 1:</strong> Purchase Property Application form
                    from OPIC office at N50,000 payable via a bank draft or
                    online.
                  </p>
                  <p>
                    <strong>Step 2:</strong> Return filled form to the office
                    with a bank draft for property, for payment plan return with
                    a letter requesting specified payment option.
                  </p>
                  <p>
                    <strong>Step 3:</strong> Receive letter of allocation from
                    OPIC.
                    <br />
                    <span className='text-danger'>
                      *Steps differ for buyers through payment option as
                      physical meetings and verifications will be required.
                    </span>
                  </p>
                </li>
                <li>
                  <input type='checkbox' />
                  <i></i>
                  <h2>
                    Are there hidden charges when I buy property from OPIC?
                  </h2>
                  <p>
                    After paying for property, the only other charge is the
                    administrative charge which is 10% of property cost. The
                    Charge covers for VAT, and Ogun State Levy fees.
                  </p>
                </li>
                <li>
                  <input type='checkbox' />
                  <i></i>
                  <h2>
                    Does OPIC Advantage and OPIC Mortgage mean I must complete
                    payment between 3 years or in 20 years?
                  </h2>
                  <p>
                    No, flexible payment terms are available that can be
                    designed to fit your needs.
                  </p>
                </li>

                <li>
                  <input type='checkbox' />
                  <i></i>
                  <h2>Are Co-operative sales packages available?</h2>
                  <p>
                    Yes, it is possible to get a co-operative package for
                    communities, institutions or organizations?
                  </p>
                </li>

                <li>
                  <input type='checkbox' />
                  <i></i>
                  <h2>
                    Is it possible to reserve a house before making financial
                    commitment?
                  </h2>
                  <p>
                    No, houses cannot be reserved without a financial
                    commitment. 20% initial deposit must be made for OPIC
                    Advantage and OPIC Mortgage.
                  </p>
                </li>
              </ul>

              <h5>About Buildings and Estates</h5>

              <ul>
                <li>
                  <input type='checkbox' />
                  <i></i>
                  <h2>What is the level of finishing of OPIC’s properties?</h2>
                  <p>
                    OPIC’s properties are sold as fully completed units and have
                    the following completed: Windows and burglary proofs
                    installed, Wiring completed, Bathroom, Water closet’s,
                    kitchen cabinets, conduits, taps and electric fittings
                    installed.
                  </p>
                </li>
                <li>
                  <input type='checkbox' />
                  <i></i>
                  <h2>What amenities are available in OPIC Estates?</h2>
                  <p>
                    OPIC estates, typically have the following amenities: Paved
                    road networks, Streetlights, Drainage systems, Drainage
                    systems, Security and Patrol, Perimeter fencing, Dedicated
                    Power Supply, ortable water and packaged sewage plants.
                  </p>
                </li>
                <li>
                  <input type='checkbox' />
                  <i></i>
                  <h2>Are OPIC estates Serviced estates?</h2>
                  <p>
                    Yes, OPIC estates are serviced - maintenance is handled by
                    OPIC, in some estates, offerings such as internet connection
                    and stable electricity are offered. Cost of estate servicing
                    varies per estate and is paid monthly or yearly.
                  </p>
                </li>

                <li>
                  <input type='checkbox' />
                  <i></i>
                  <h2>
                    Do residents in OPIC estates have access to facilities?
                  </h2>
                  <p>
                    Yes, residents have free access to all facilities within
                    their estate.
                  </p>
                </li>
              </ul>

              <h5>About Property Visits</h5>
              <ul>
                <li>
                  <input type='checkbox' />
                  <i></i>
                  <h2>Can I Visit the site?</h2>
                  <p>
                    Yes, intending home owners can schedule a site visit. To do
                    that, Call: 08112892020.
                  </p>
                </li>
                <li>
                  <input type='checkbox' />
                  <i></i>
                  <h2>When can I visit the site?</h2>
                  <p>
                    Site Visits are typically arranged for weekdays between
                    9:00am -5:00pm. However, exceptions can be made.
                  </p>
                </li>
                <li>
                  <input type='checkbox' />
                  <i></i>
                  <h2>Can I Visit the Site alone?</h2>
                  <p>
                    The state of security on the sites/properties makes it
                    impossible to do so. Intending visitors are advised to
                    notify the office of their intention so access to site can
                    be granted.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <FooterPage />
      </div>
    );
  }
}
