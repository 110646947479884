import React from 'react';

import PropertyTypeListItem from '../PropertyTypeListItem';


const PropertyTypeList = ({ statuses, propertyTypes, hometype_price, setHomeTypePriceInParent }) => {
  const list = hometype_price.length ? hometype_price : [{}]

  return list.map((property, index) => {
    const setHomeTypePriceItem = (property) => {
      const clone = [...hometype_price];
      clone[index] = property;
      setHomeTypePriceInParent(clone);
    };

    const removeItem = () => {
      const clone = [...hometype_price];
      clone.splice(index, 1);
      setHomeTypePriceInParent(clone);
    };

    return (
      <PropertyTypeListItem {...{
          key: index, property, statuses, propertyTypes, setHomeTypePriceItem, removeItem, index
        }}
      />
    );
  });
}
 
export default PropertyTypeList;