import React, { useState, useEffect } from 'react';

import './PropertyGalleryFormGroup.css';
import { getInputErrorElement } from '../../utils/formUtils';
import { requireFieldErrorMsg } from '../../utils/errorMessageUtils';


const PropertyGalleryFormGroup = ({ setPhotos, setPropertyCoverImage }) => {
  const [localPhotos, setLocalPhotos] = useState([]);
  const [featuredImage, setFeaturedImage] = useState('');

  const handleImagesChange = ({ target: { files } }) => {
    const fileList = Array.from(files).slice(0, 4);
    const photos = [];
    fileList.forEach((file, index) => {
      const reader = new FileReader();
      reader.onload = ({ target }) => photos[index] = target.result;
      reader.readAsDataURL(file);
    });
    setPhotos(photos);
    setLocalPhotos(fileList.map(URL.createObjectURL));
  };

  const handleFeaturedImageChange = ({ target: { files: [file] } }) => {
    const reader = new FileReader();
    reader.onload = ({ target }) => setPropertyCoverImage(target.result);
    reader.readAsDataURL(file);
    const localUrl = URL.createObjectURL(file);
    setFeaturedImage(localUrl);
  };

  useEffect(() => {
    return () => {
      localPhotos.map(URL.revokeObjectURL);
      if (featuredImage) URL.revokeObjectURL(featuredImage);
    };
  });

  return (
    <div className="photos-gallery-form-group row mb-5">
    <div className="featured-image col-md-2 mr-4">
        <input
          type="file"
          onChange={handleFeaturedImageChange}
          className='position-absolute'
          accept='.png,.jpg,.jpeg'
          required
        />
        <div className="image-wrapper d-flex text-capitalize">
          {
            featuredImage ? (
              <img src={featuredImage} alt="featured cover" className='img-fluid' />
            ) : ''
          }
        </div>
        <div className="hover-show-text position-absolute top-0">Click to upload</div>
        {getInputErrorElement()}
      </div>
      <div className="col-md-8 offset-md-1 row">
        <ul className="image-preview-wrapper col-md-12 mb-3 row list-unstyled p-2">
          {
            localPhotos.map((imageUrl, index) => {
              return (
                <li key={index} className='col-md-3'>
                  <img src={imageUrl} alt="uploaded images" className='img-fluid' />
                </li>
              );
            })
          }
        </ul>

        <div className="input-button-wrapper position-relative row">
          <input
            type="file"
            multiple
            onChange={handleImagesChange}
            className='form-control position-absolute'
            accept='.png,.jpg,.jpeg'
            required
          />
          <span className="upload-btn text-capitalize d-flex justify-content-center align-items-center">upload images</span>
          {getInputErrorElement()}
        </div>
      </div>
    </div>
  );
}
 
export default PropertyGalleryFormGroup;