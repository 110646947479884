import React from "react";
import {validateForm} from "../../utils/formUtils";
import {useState} from "react";

const PropertyFilterForm = ({history}) => {
  const [location, setLocation] = useState("");
  const [bedrooms, setBedrooms] = useState("");
  const [bathrooms, setBathrooms] = useState("");
  const [maxPrice, setMaxPrice] = useState("");

  const setters = {
    location: setLocation,
    bedrooms: setBedrooms,
    bathrooms: setBathrooms,
    maxPrice: setMaxPrice
  };

  const getQuery = () => {
    return `location=${encodeURIComponent(
      location
    )}&bedrooms=${encodeURIComponent(bedrooms)}&bathrooms=${encodeURIComponent(
      bathrooms
    )}&price=${maxPrice}`;
  };

  const handleChange = ({target: {name, value}}) => setters[name](value);

  const handleSubmit = event => {
    validateForm(event);
    const query = getQuery();
    history.push("/find-property?" + query);
  };

  return (
    <form
      id='wf-form-Site-Filter'
      name='wf-form-Site-Filter'
      className='filter-form'
      onSubmit={handleSubmit}
    >
      <div id='filter-name-location' className='filter-input-wrapper'>
        <div className='filter-input-icon'></div>
        <input
          id='name-location'
          type='text'
          className='filter-input o-input'
          maxLength='256'
          name='location'
          placeholder='Name, Neighborhood, City'
          value={location}
          onChange={handleChange}
        />
      </div>
      <div id='filter-bedrooms' className='filter-input-wrapper'>
        <div className='filter-input-icon'></div>
        <input
          type='number'
          className='filter-input o-input'
          maxLength='10'
          name='bedrooms'
          data-name='Bedrooms'
          placeholder='Bedrooms'
          id='Bedrooms'
          value={bedrooms}
          onChange={handleChange}
        />
      </div>
      <div id='filter-bathrooms' className='filter-input-wrapper'>
        <div className='filter-input-icon'></div>
        <input
          type='number'
          className='filter-input o-input'
          maxLength='10'
          name='bathrooms'
          data-name='Bathrooms'
          placeholder='Bathrooms'
          id='Bathrooms'
          value={bathrooms}
          onChange={handleChange}
        />
      </div>
      <div id='filter-price' className='filter-input-wrapper'>
        <div className='filter-input-icon'></div>
        <input
          type='number'
          className='filter-input o-input'
          maxLength='256'
          name='maxPrice'
          data-name='Price'
          placeholder='Maximum Price'
          id='Price'
          value={maxPrice}
          onChange={handleChange}
        />
      </div>
      <input
        type='submit'
        value='Filter'
        data-wait='Please wait...'
        id=''
        className='filter-input-btn o-button'
      />
    </form>
  );
};

export default PropertyFilterForm;
