import React, { Component } from 'react';
import AmenityList from '../../modules/sitemaps/AmenityList';
import PropertyTypes from '../../modules/sitemaps/PropertyTypes';
import PropertyInfo from '../../modules/sitemaps/PropertyInfo';
import SegmentSlider from '../../modules/sitemaps/SegmentSlider';
import Helmet from 'react-helmet';
import http from '../../config/axiosConfig';
import { Link } from 'react-router-dom';
import { deleteProperty } from '../../utils/dashboardUtils';


class AdminPropertyDetails extends Component {
  defaultState = {
    propertyInfo: [],
    propertyImagesArray: [],
    amenities: [],
    rate: 17.5,
    year: 20
  };
  state = {...this.defaultState};

  componentDidMount() {
    this.getPropertyInfo();
  }

  deleteProperty = async () => {
    const { history, match: { params: { slug } } } = this.props;
    console.log(history, slug);
    try {
      await deleteProperty(history, slug);
    } catch (error) {}
  };

  getPropertyInfo = () => {
    try {
      http.get("/admin/property-detail/" + this.props.match.params.slug).then(res => {
        this.setState({
          propertyInfo: res.data,
          amenityArray: res.data.amenity
          //   propertyTypeArray: res.data.propertyprice
        });
      });
    } catch (error) {}
  };


  render() { 
    return (
      <div className='container-fluid p-0'>
        <Helmet>
          <meta charset='utf-8' />
          <meta name='viewport' content='width=device-width, initial-scale=1' />
          <title>
            Property Information | OPIC - Expanding the frontiers of Ogun State
          </title>
          <link
            rel='canonical'
            href={
              "https://opicgroup.com/find-property" +
              this.state.propertyInfo.slug
            }
          />
        </Helmet>
        <div
          data-animation='slide'
          data-duration='500'
          data-infinite='1'
          className='property-slider o-slider'
        >
          <SegmentSlider sliders={this.state.propertyInfo.propertyphoto} />

          <div
            className='slider-left-arrow o-slider-arrow-left'
            onClick={this.goToPreviousSlide}
          >
            <div
              className='slider-left-arrow-icon o-icon-slider-left'
              onClick={this.goToNextSlide}
            >
              <span className='slider-icons'></span>
            </div>
          </div>
          <div className='slider-right-arrow o-slider-arrow-right'>
            <div className='slider-right-arrow-icon o-icon-slider-right'>
              <span className='slider-icons'></span>
            </div>
          </div>
          <div className='property-slider-nav o-slider-nav o-round'></div>
        </div>

        <div className='property-details-wrapper'>
          <div className='property-info-wrapper'>
            <div id='' className='property-info'>
              <PropertyInfo
                name={this.state.propertyInfo.property_name}
                state={this.state.propertyInfo.property_state}
                bedroom={this.state.propertyInfo.property_bedrooms}
                bathroom={this.state.propertyInfo.property_bathrooms}
                sqm={this.state.propertyInfo.property_size}
                status={this.state.propertyInfo.property_status}
              />
              <div className='separator'></div>

              <div className='view-on-map-wrapper'>
                <div className='view-on-map'>
                  <h2 className='view-on-map-icon'></h2>
                  <a href='#' className='view-on-map-link'>
                    View this property on the map
                  </a>
                </div>
              </div>

              <div className='separator'></div>

              <div className='info-section'>
                <div className='description-wrapper'>
                  <h2 className='info-section-title'>Description</h2>
                  <div className='info-description'>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.propertyInfo.property_description
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className='separator'></div>

              <div className='info-section'>
                <div className='description-wrapper'>
                  <h2 className='info-section-title'>Units &amp; Prices</h2>
                  <PropertyTypes
                    types={this.state.propertyInfo.propertyprice}
                  />
                </div>
              </div>

              <div className='separator'></div>

              <div className='info-section'>
                <div className='description-wrapper'>
                  <h2 className='info-section-title'>Amenities</h2>
                  <AmenityList amenities={this.state.amenityArray} />
                </div>
              </div>

              <div className='separator'></div>

              <div className="buttons d-flex row justify-content-around mt-5">
                <div className="button-wrapper d-flex justify-content-center col-xs-12 col-md-6 mb-3">
                  <Link to={`/admin/properties/edit/${this.state.propertyInfo.slug}`} className='btn btn-primary'>Edit this property</Link>
                </div>
                <div className="button-wrapper d-flex justify-content-center col-xs-12 col-md-6 mb-3">
                  <button onClick={this.deleteProperty} className='btn btn-danger'>Delete this property</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
 
export default AdminPropertyDetails;