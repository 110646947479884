import React, {Component, createRef} from "react";

import './SegmentSlider.css';

export default class SegmentSlider extends Component {
  state = {
    minIndex: 0,
    currentIndex: 0
  };

  constructor(props) {
    super(props);
    this.imageRefs = [];
  };

  componentDidMount() {
    if (window.innerWidth >= 992) {
      this.setState({ currentIndex: 2, minIndex: 2 });
    }
  }

  componentDidUpdate({ sliders: prevSliders }) {
    const { sliders } = this.props;
    if (!prevSliders && sliders) {
      const { currentIndex } = this.state;
      this.imageRefs = sliders.map(() => createRef(null));
      this.setState({ currentIndex });
    }
  };

  scrollToNextImage = () => {
    const { sliders } = this.props;
    const { currentIndex, minIndex } = this.state;
    if (sliders.length - 1 > currentIndex) {
      let newIndex;
      if (currentIndex < minIndex) newIndex = minIndex + 1;
      else newIndex = currentIndex + 1;
      this.imageRefs[newIndex].current.scrollIntoView(false);
      this.setState({ currentIndex: newIndex });
    }
  };

  scrollToPreviousImage = () => {
    const { sliders } = this.props;
    const { currentIndex } = this.state;
    if (currentIndex && sliders.length) {
      let newIndex;
      if (currentIndex === sliders.length - 1 && window.innerWidth >= 992) newIndex = currentIndex - 3;
      else newIndex = currentIndex - 1;
      if (newIndex >= 0) {
        this.imageRefs[newIndex].current.scrollIntoView(false);
        this.setState({ currentIndex: newIndex });
      }
    }
  };

  renderSlider = () => {
    const mapSlider =
      this.props.sliders === undefined ? (
        <p>No entries found</p>
      ) : (
        this.props.sliders.map((slide, i) => (
          <div key={slide.id} id={i} className='property-image-slide o-slide' ref={this.imageRefs[i]}>
            <div
              className='property-gallery-image'
              style={{backgroundImage: `url(${slide.filename})`}}
            ></div>
          </div>
        ))
      );
    return mapSlider;
  };

  render() {
    return (
      <div className='property-slider-mask o-slider-mask'>
        <div className="image-slides">
          {this.renderSlider()}
        </div>

        <div className="slider-controls">
          <div className='slider-left-arrow slider-control o-slider-arrow-left' onClick={this.scrollToPreviousImage}>
            <div className='slider-left-arrow-icon o-icon-slider-left'>
              <span className='slider-icons'></span>
            </div>
          </div>

          <div className='slider-right-arrow slider-control o-slider-arrow-right' onClick={this.scrollToNextImage}>
            <div className='slider-right-arrow-icon o-icon-slider-right'>
              <span className='slider-icons'></span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
