import React from "react";

import "./dataTable.css";
import DataTableRow from "../DataTableRow";

const DataTable = ({
  headings,
  wrapperStyle,
  data: datalist,
  generateActions,
  wrapperClassName,
}) => {
  return (
    <div
      className={`parent-wrapper mt-2 card${
        wrapperClassName ? ` ${wrapperClassName}` : ""
      }`}
      style={wrapperStyle}
    >
      <div className="table-responsive">
      <table className="table center-aligned-table">
        <thead>
          <tr className='text-capitalize'>
            {headings.map(({ title: heading }, index) => (
              <th key={index}>{heading}</th>
            ))}
            <th className='actions-head'>actions</th>
          </tr>
        </thead>
        <tbody>
          {
            datalist.map((data, key) => {
              const { viewDetails, editItem, deleteItem } = generateActions(data.slug);
              return <DataTableRow {...{ data, headings, key, viewDetails, editItem, deleteItem }} />
            })
          }
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default DataTable;
