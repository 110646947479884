import React, {Component} from "react";
import {removeCommas} from "../../utils/stringUtils";
import FinancePlusWidget from "../sitemaps/FinancePlusWidget";

class MortgageOption extends Component {
  render() {
    return (
      <div
        className='mortgage-widget-wrapper'
        style={{
          display: this.props.mortgageOption ? "" : "none"
        }}
      >
        <financeplus-widget
          developer='opic'
          has-property='true'
          url='https://opic.newhomes.ng'
          primary-color='#136b3d'
          font='DM Sans, DM Serif Display, sans-serif, Roboto'
          property-value={new Intl.NumberFormat().format(
            Math.round(removeCommas(this.props.price || ""))
          )}
        />
      </div>
    );
  }
}

export default MortgageOption;
