import React from "react";
import {Link} from "react-router-dom";
import * as Icons from "react-feather";

import { logout } from "../../utils/authUtils";
import logo from '../../resources/opic state logo 2.png';

const HeaderPage = ({ history }) => {
  const signout = () => logout(history);

  return (
    <nav className='navbar navbar-fixed-top'>
      <div className='container-fluid'>
        <div className='navbar-btn'>
          <button type='button' className='btn-toggle-offcanvas'>
            <Icons.AlignJustify
              color='#666'
              className='lnr lnr-menu'
              size='25px'
            />
          </button>
        </div>

        <div className='navbar-brand'>
          <Link to='/'>
            <img src={logo} className='img-responsive logo img-fluid' />
          </Link>
        </div>

        <div className='navbar-right'>
          {/* <form id='navbar-search' className='navbar-form search-form'>
            <input
              className='form-control'
              placeholder='Search here...'
              type='text'
            />
            <button type='button' className='btn btn-default'>
              <i className='icon-magnifier'></i>
            </button>
          </form> */}

          <div id='navbar-menu'>
            <ul className='nav navbar-nav'>
              <li>
                <Link to='/' className='icon-menu d-none d-sm-block'>
                  <i className='icon-bubbles'></i>
                </Link>
              </li>
              <li>
                <Link to='/' className='icon-menu d-none d-sm-block'>
                  <i className='icon-envelope'></i>
                  <span className='notification-dot'></span>
                </Link>
              </li>

              <li title='Log out' onClick={signout}>
                <Link to='/' className='icon-menu'>
                  <i className='icon-login'></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default HeaderPage;
