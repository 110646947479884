import React from 'react';

import BarChart from '../BarChart';
import './PropertyTotalStats.css';


const ByCityValueStats = ({ wrapperClassName, data }) => {
  return (
    <div className='col-sm-12 col-md-6 mb-4 pr-3'>
    <div className={`properties-total-stats-wrapper${wrapperClassName ? ` ${wrapperClassName}` : ''}`}>
      <h5 className='text-capitalize'>total value &amp; statistics</h5>
      <div className="bar-chart-wrapper mt-4">
        <BarChart
          {...{
            data,
            xLabel: "property_city",
            fills: ["#FF9E27"],
            dataKeys: ["value"],
            backgrounds: ["none"],
            wrapperClassName: 'income-chart-wrapper',
            margin: {
              left: 20
            },
            wrapperStyle: {
              width: '100%',
              height: '100%'
            }
          }}
        />
        </div>

      {/* <div className="total-value mt-2 text-center font-weight-bold d-flex justify-content-between">
        <h6 className='text-uppercase'>total value:</h6>
        <h6>₦ {formattedValue}</h6>
      </div> */}

      </div>
    </div>
  );
}
 
export default ByCityValueStats;