import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import http from "../../config/axiosConfig";

// import * as Icons from "react-feather";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";

import PropertyList from "../../modules/sitemaps/PropertyList";
import FooterPage from "../../components/FooterPage";

import opicBanner from "../../resources/family-banner.png";
import makunWrapper from "../../resources/makun1.JPG";
import devLogo from "../../resources/ogun-logo.jpg";
import blogSample3 from "../../resources/blog3.jpg";
import officeTowerImg from "../../resources/office_tower.jpg";
import newDawn from "../../resources/newdawn3.jpg";
import opicHQ from "../../resources/opichq2.jpg";
import orangeValley from "../../resources/ov2.jpg";
import mtrGarden from "../../resources/mtri1.jpg";
import alamala from "../../resources/alamala.jpg";
import opicPlaza from "../../resources/opicplaza2.jpg";
import opicEvent from "../../resources/OPICEventCentre1.jpg";
import magboro from "../../resources/magboro.jpg";
import agbaraIgbesa from "../../resources/agbara1.jpg";

// import standardCharteredLogo from "../../resources/standard-chartered.png";
// import stanbicIbtcLogo from "../../resources/stanbic-ibtc.png";
// import accessBankLogo from "../../resources/access-bank.png";
// import firstTrustLogo from "../../resources/first-trust-theme-logo.png";
// import homeBaseLogo from "../../resources/homebase.png";
// import FMBNLogo from "../../resources/FMBN_opic_logo.png";
// import FhfLogo from "../../resources/fhf-logo.png";
// import nmrcLogo from "../../resources/nmrc.png";
// import missionIcon from "../../resources/mission.png";
// import visionIcon from "../../resources/eye.png";
// import valuesIcon from "../../resources/values.png";
// import coupleBanner from "../../resources/opicCouple.png";
// import portfolioIcon from "../../resources/icons-04.png";
// import eligibilityIcon from "../../resources/icons-01.png";
// import dreamIcon from "../../resources/icons-02.png";

import "./SiteMap.css";

class HomePage extends Component {
  defaultState = {
    properties: [],
    responsive: {
      0: {
        items: 2,
      },
      450: {
        items: 3,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 6,
      },
    },
    project_responsive: {
      0: {
        items: 2,
      },
      450: {
        items: 3,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };
  state = {...this.defaultState};

  componentDidMount() {
    this.getProperty();
  }

  getProperty = async () => {
    try {
      const res = await http.get("/all-featured-properties");
      this.setState({
        properties: res.data,
      });
    } catch (error) {}
  };

  render() {
    return (
      <div className='container-fluid p-0'>
        <Helmet>
          <meta charset='utf-8' />
          <meta name='viewport' content='width=device-width, initial-scale=1' />
          <title>Welcome | OPIC | Expanding the frontiers of Ogun State</title>
          <link rel='canonical' href='https://opicgroup.com' />
        </Helmet>
        {/* <HeaderPageHeader /> */}
        <div className='page-wrapper'>
          <section id='hero-section' className='hero-section'>
            <div id='' className='hero-left'>
              <h1 className='site-heading primary'>OPIC Group.</h1>
              <p className='site-paragraph'>
                Expanding the Frontier of Ogun State
              </p>
              <div className='dual-cta-wrapper'>
                <Link to='/home-purchase' className='site-button o-button'>
                  Buy a Property
                </Link>
                <Link
                  to='/company-profile'
                  className='site-button alt o-button'
                >
                  About OPIC
                </Link>
              </div>
            </div>
            <div className='hero-right'>
              {/* <img src={opicBanner} alt='' className='hero-image' /> */}
            </div>
          </section>

          <section id='partner-logos' className='intro-section'>
            <OwlCarousel
              id=''
              className='partner-logos-wrapper'
              items={7}
              autoplay={true}
              loop
              responsive={this.state.responsive}
              autoplayTimeout={5000}
            >
              <div className='slide'>
                <div className='partner-logo-img standard-chartered'></div>
              </div>

              <div className='slide'>
                <div className='partner-logo-img stanbic-ibtc'></div>
              </div>

              <div className='slide'>
                <div className='partner-logo-img fmbn'></div>
              </div>

              <div className='slide'>
                <div className='partner-logo-img access-bank'></div>
              </div>

              <div className='slide'>
                <div className='partner-logo-img fttrust'></div>
              </div>

              <div className='slide'>
                <div className='partner-logo-img fhf'></div>
              </div>

              <div className='slide'>
                <div className='partner-logo-img homebase'></div>
              </div>

              <div className='slide'>
                <div className='partner-logo-img nmrc'></div>
              </div>
            </OwlCarousel>
          </section>

          <section id='our-projects'>
            <div className='container px-0'>
              <div className='our-projects-container'>
                <div className='row'>
                  <div className='col-md-4 mt-3'>
                    <div class='property-card'>
                      <div
                        class='property-image-holder'
                        style={{
                          backgroundImage: `url(${makunWrapper})`,
                        }}
                      ></div>
                      <div class='property-deets'>
                        <div class='card-price-holder'>
                          <div class='card-property-status text-uppercase'>
                            Residential
                          </div>
                          <h4 class='card-price'>₦25,000,000</h4>
                        </div>
                        <div class='card-name-holder'>
                          <h3 class='card-title'>New Makun City</h3>
                          <div class='property-card-extras'>
                            <div class='card-location'>Ogun State</div>
                          </div>
                        </div>
                        <div className='project-logo'>
                          <img src={devLogo} alt='' />
                        </div>
                        <div class='card-bed-bath-holder'>
                          <div class='card-inner-side'>
                            <div class='card-icon'></div>
                            <div class='card-bed-bath'>2 Beds</div>
                          </div>
                          <div class='card-inner-divider'></div>
                          <div class='card-inner-side'>
                            <div class='card-icon'></div>
                            <div class='card-bed-bath'>2 Baths</div>
                          </div>
                          <div class='card-inner-divider'></div>
                          <div class='card-inner-side'>
                            <div class='card-icon'></div>
                            <div class='card-bed-bath'>650 Sqm</div>
                          </div>
                        </div>
                        <div class='mortgage-deets-holder'>
                          <div class='card-inner-block'>
                            <p class='mortgage-heading'>Buy on a mortgage</p>
                            <div class='mortgage-wrapper'>
                              <p class='mortage-value'>₦30,000</p>
                              <div class='mortgage-freq'>/month</div>
                            </div>
                          </div>
                          <div class='card-inner-divider long'></div>
                          <div class='card-inner-block'>
                            <p class='repayment-heading'>Payback over</p>
                            <div class='repayment-wrapper'>
                              <p class='repayment-period'>20</p>
                              <div class='repayment-freq'>/Years</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-4 mt-3'>
                    <div class='property-card'>
                      <div
                        class='property-image-holder'
                        style={{
                          backgroundImage: `url(${orangeValley})`,
                        }}
                      ></div>
                      <div class='property-deets'>
                        <div class='card-price-holder'>
                          <div class='card-property-status text-uppercase'>
                            Residential
                          </div>
                          <h4 class='card-price'>₦25,000,000</h4>
                        </div>
                        <div class='card-name-holder'>
                          <h3 class='card-title'>Orange Valley Estate</h3>
                          <div class='property-card-extras'>
                            <div class='card-location'>Oke Mosan, Abeokuta</div>
                          </div>
                        </div>
                        <div className='project-logo'>
                          <img src={devLogo} alt='' />
                        </div>
                        <div class='card-bed-bath-holder'>
                          <div class='card-inner-side'>
                            <div class='card-icon'></div>
                            <div class='card-bed-bath'>2 Beds</div>
                          </div>
                          <div class='card-inner-divider'></div>
                          <div class='card-inner-side'>
                            <div class='card-icon'></div>
                            <div class='card-bed-bath'>2 Baths</div>
                          </div>
                          <div class='card-inner-divider'></div>
                          <div class='card-inner-side'>
                            <div class='card-icon'></div>
                            <div class='card-bed-bath'>650 Sqm</div>
                          </div>
                        </div>
                        <div class='mortgage-deets-holder'>
                          <div class='card-inner-block'>
                            <p class='mortgage-heading'>Buy on a mortgage</p>
                            <div class='mortgage-wrapper'>
                              <p class='mortage-value'>₦30,000</p>
                              <div class='mortgage-freq'>/month</div>
                            </div>
                          </div>
                          <div class='card-inner-divider long'></div>
                          <div class='card-inner-block'>
                            <p class='repayment-heading'>Payback over</p>
                            <div class='repayment-wrapper'>
                              <p class='repayment-period'>20</p>
                              <div class='repayment-freq'>/Years</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-4 mt-3'>
                    <div class='property-card'>
                      <div
                        class='property-image-holder'
                        style={{
                          backgroundImage: `url(${mtrGarden})`,
                        }}
                      ></div>
                      <div class='property-deets'>
                        <div class='card-price-holder'>
                          <div class='card-property-status text-uppercase'>
                            Residential
                          </div>
                          <h4 class='card-price'>₦25,000,000</h4>
                        </div>
                        <div class='card-name-holder'>
                          <h3 class='card-title'>MTR Gardens</h3>
                          <div class='property-card-extras'>
                            <div class='card-location'>Isheri, Lagos</div>
                          </div>
                        </div>
                        <div className='project-logo'>
                          <img src={devLogo} alt='' />
                        </div>
                        <div class='card-bed-bath-holder'>
                          <div class='card-inner-side'>
                            <div class='card-icon'></div>
                            <div class='card-bed-bath'>2 Beds</div>
                          </div>
                          <div class='card-inner-divider'></div>
                          <div class='card-inner-side'>
                            <div class='card-icon'></div>
                            <div class='card-bed-bath'>2 Baths</div>
                          </div>
                          <div class='card-inner-divider'></div>
                          <div class='card-inner-side'>
                            <div class='card-icon'></div>
                            <div class='card-bed-bath'>650 Sqm</div>
                          </div>
                        </div>
                        <div class='mortgage-deets-holder'>
                          <div class='card-inner-block'>
                            <p class='mortgage-heading'>Buy on a mortgage</p>
                            <div class='mortgage-wrapper'>
                              <p class='mortage-value'>₦30,000</p>
                              <div class='mortgage-freq'>/month</div>
                            </div>
                          </div>
                          <div class='card-inner-divider long'></div>
                          <div class='card-inner-block'>
                            <p class='repayment-heading'>Payback over</p>
                            <div class='repayment-wrapper'>
                              <p class='repayment-period'>20</p>
                              <div class='repayment-freq'>/Years</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-4 mt-3'>
                    <div class='property-card'>
                      <div
                        class='property-image-holder'
                        style={{
                          backgroundImage: `url(${alamala})`,
                        }}
                      ></div>
                      <div class='property-deets'>
                        <div class='card-price-holder'>
                          <div class='card-property-status text-uppercase'>
                            Residential
                          </div>
                          <h4 class='card-price'>₦25,000,000</h4>
                        </div>
                        <div class='card-name-holder'>
                          <h3 class='card-title'>Alamala New Town</h3>
                          <div class='property-card-extras'>
                            <div class='card-location'>
                              Abeokuta-Sokoto Expressway
                            </div>
                          </div>
                        </div>
                        <div className='project-logo'>
                          <img src={devLogo} alt='' />
                        </div>
                        <div class='card-bed-bath-holder'>
                          <div class='card-inner-side'>
                            <div class='card-icon'></div>
                            <div class='card-bed-bath'>2 Beds</div>
                          </div>
                          <div class='card-inner-divider'></div>
                          <div class='card-inner-side'>
                            <div class='card-icon'></div>
                            <div class='card-bed-bath'>2 Baths</div>
                          </div>
                          <div class='card-inner-divider'></div>
                          <div class='card-inner-side'>
                            <div class='card-icon'></div>
                            <div class='card-bed-bath'>650 Sqm</div>
                          </div>
                        </div>
                        <div class='mortgage-deets-holder'>
                          <div class='card-inner-block'>
                            <p class='mortgage-heading'>Buy on a mortgage</p>
                            <div class='mortgage-wrapper'>
                              <p class='mortage-value'>₦30,000</p>
                              <div class='mortgage-freq'>/month</div>
                            </div>
                          </div>
                          <div class='card-inner-divider long'></div>
                          <div class='card-inner-block'>
                            <p class='repayment-heading'>Payback over</p>
                            <div class='repayment-wrapper'>
                              <p class='repayment-period'>20</p>
                              <div class='repayment-freq'>/Years</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-4 mt-3'>
                    <div class='property-card'>
                      <div
                        class='property-image-holder'
                        style={{
                          backgroundImage: `url(${newDawn})`,
                        }}
                      ></div>
                      <div class='property-deets'>
                        <div class='card-price-holder'>
                          <div class='card-property-status text-uppercase'>
                            Residential
                          </div>
                          <h4 class='card-price'>₦25,000,000</h4>
                        </div>
                        <div class='card-name-holder'>
                          <h3 class='card-title'>
                            New Dawn Gardens &amp; Terraces
                          </h3>
                          <div class='property-card-extras'>
                            <div class='card-location'>
                              Agbara/Igbesa Estate
                            </div>
                          </div>
                        </div>
                        <div className='project-logo'>
                          <img src={devLogo} alt='' />
                        </div>
                        <div class='card-bed-bath-holder'>
                          <div class='card-inner-side'>
                            <div class='card-icon'></div>
                            <div class='card-bed-bath'>2 Beds</div>
                          </div>
                          <div class='card-inner-divider'></div>
                          <div class='card-inner-side'>
                            <div class='card-icon'></div>
                            <div class='card-bed-bath'>2 Baths</div>
                          </div>
                          <div class='card-inner-divider'></div>
                          <div class='card-inner-side'>
                            <div class='card-icon'></div>
                            <div class='card-bed-bath'>650 Sqm</div>
                          </div>
                        </div>
                        <div class='mortgage-deets-holder'>
                          <div class='card-inner-block'>
                            <p class='mortgage-heading'>Buy on a mortgage</p>
                            <div class='mortgage-wrapper'>
                              <p class='mortage-value'>₦30,000</p>
                              <div class='mortgage-freq'>/month</div>
                            </div>
                          </div>
                          <div class='card-inner-divider long'></div>
                          <div class='card-inner-block'>
                            <p class='repayment-heading'>Payback over</p>
                            <div class='repayment-wrapper'>
                              <p class='repayment-period'>20</p>
                              <div class='repayment-freq'>/Years</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-4 mt-3'>
                    <div class='property-card'>
                      <div
                        class='property-image-holder'
                        style={{
                          backgroundImage: `url(${opicPlaza})`,
                        }}
                      ></div>
                      <div class='property-deets'>
                        <div class='card-price-holder'>
                          <div class='card-property-status text-uppercase'>
                            Commercial
                          </div>
                          <h4 class='card-price'>₦25,000,000</h4>
                        </div>
                        <div class='card-name-holder'>
                          <h3 class='card-title'>OPIC Plaza</h3>
                          <div class='property-card-extras'>
                            <div class='card-location'>Ikeja</div>
                          </div>
                        </div>
                        <div className='project-logo'>
                          <img src={devLogo} alt='' />
                        </div>
                        <div class='card-bed-bath-holder'>
                          <div class='card-inner-side'>
                            <div class='card-icon'></div>
                            <div class='card-bed-bath'>2 Beds</div>
                          </div>
                          <div class='card-inner-divider'></div>
                          <div class='card-inner-side'>
                            <div class='card-icon'></div>
                            <div class='card-bed-bath'>2 Baths</div>
                          </div>
                          <div class='card-inner-divider'></div>
                          <div class='card-inner-side'>
                            <div class='card-icon'></div>
                            <div class='card-bed-bath'>650 Sqm</div>
                          </div>
                        </div>
                        <div class='mortgage-deets-holder'>
                          <div class='card-inner-block'>
                            <p class='mortgage-heading'>Buy on a mortgage</p>
                            <div class='mortgage-wrapper'>
                              <p class='mortage-value'>₦30,000</p>
                              <div class='mortgage-freq'>/month</div>
                            </div>
                          </div>
                          <div class='card-inner-divider long'></div>
                          <div class='card-inner-block'>
                            <p class='repayment-heading'>Payback over</p>
                            <div class='repayment-wrapper'>
                              <p class='repayment-period'>20</p>
                              <div class='repayment-freq'>/Years</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-4 mt-3'>
                    <div class='property-card'>
                      <div
                        class='property-image-holder'
                        style={{
                          backgroundImage: `url(${opicHQ})`,
                        }}
                      ></div>
                      <div class='property-deets'>
                        <div class='card-price-holder'>
                          <div class='card-property-status text-uppercase'>
                            Commercial
                          </div>
                          <h4 class='card-price'>₦25,000,000</h4>
                        </div>
                        <div class='card-name-holder'>
                          <h3 class='card-title'>
                            OPIC Headquarters and Towers
                          </h3>
                          <div class='property-card-extras'>
                            <div class='card-location'>
                              OPIC Circle, Oke-Ilewo, Abeokuta
                            </div>
                          </div>
                        </div>
                        <div className='project-logo'>
                          <img src={devLogo} alt='' />
                        </div>
                        <div class='card-bed-bath-holder'>
                          <div class='card-inner-side'>
                            <div class='card-icon'></div>
                            <div class='card-bed-bath'>2 Beds</div>
                          </div>
                          <div class='card-inner-divider'></div>
                          <div class='card-inner-side'>
                            <div class='card-icon'></div>
                            <div class='card-bed-bath'>2 Baths</div>
                          </div>
                          <div class='card-inner-divider'></div>
                          <div class='card-inner-side'>
                            <div class='card-icon'></div>
                            <div class='card-bed-bath'>650 Sqm</div>
                          </div>
                        </div>
                        <div class='mortgage-deets-holder'>
                          <div class='card-inner-block'>
                            <p class='mortgage-heading'>Buy on a mortgage</p>
                            <div class='mortgage-wrapper'>
                              <p class='mortage-value'>₦30,000</p>
                              <div class='mortgage-freq'>/month</div>
                            </div>
                          </div>
                          <div class='card-inner-divider long'></div>
                          <div class='card-inner-block'>
                            <p class='repayment-heading'>Payback over</p>
                            <div class='repayment-wrapper'>
                              <p class='repayment-period'>20</p>
                              <div class='repayment-freq'>/Years</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-4 mt-3'>
                    <div class='property-card'>
                      <div
                        class='property-image-holder'
                        style={{
                          backgroundImage: `url(${opicEvent})`,
                        }}
                      ></div>
                      <div class='property-deets'>
                        <div class='card-price-holder'>
                          <div class='card-property-status text-uppercase'>
                            Commercial
                          </div>
                          <h4 class='card-price'>₦25,000,000</h4>
                        </div>
                        <div class='card-name-holder'>
                          <h3 class='card-title'>
                            OPIC House &amp; Event Center
                          </h3>
                          <div class='property-card-extras'>
                            <div class='card-location'>Isheri</div>
                          </div>
                        </div>
                        <div className='project-logo'>
                          <img src={devLogo} alt='' />
                        </div>
                        <div class='card-bed-bath-holder'>
                          <div class='card-inner-side'>
                            <div class='card-icon'></div>
                            <div class='card-bed-bath'>2 Beds</div>
                          </div>
                          <div class='card-inner-divider'></div>
                          <div class='card-inner-side'>
                            <div class='card-icon'></div>
                            <div class='card-bed-bath'>2 Baths</div>
                          </div>
                          <div class='card-inner-divider'></div>
                          <div class='card-inner-side'>
                            <div class='card-icon'></div>
                            <div class='card-bed-bath'>650 Sqm</div>
                          </div>
                        </div>
                        <div class='mortgage-deets-holder'>
                          <div class='card-inner-block'>
                            <p class='mortgage-heading'>Buy on a mortgage</p>
                            <div class='mortgage-wrapper'>
                              <p class='mortage-value'>₦30,000</p>
                              <div class='mortgage-freq'>/month</div>
                            </div>
                          </div>
                          <div class='card-inner-divider long'></div>
                          <div class='card-inner-block'>
                            <p class='repayment-heading'>Payback over</p>
                            <div class='repayment-wrapper'>
                              <p class='repayment-period'>20</p>
                              <div class='repayment-freq'>/Years</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-4 mt-3'>
                    <div class='property-card'>
                      <div
                        class='property-image-holder'
                        style={{
                          backgroundImage: `url(${magboro})`,
                        }}
                      ></div>
                      <div class='property-deets'>
                        <div class='card-price-holder'>
                          <div class='card-property-status text-uppercase'>
                            Residential
                          </div>
                          <h4 class='card-price'>₦25,000,000</h4>
                        </div>
                        <div class='card-name-holder'>
                          <h3 class='card-title'>Magboro New Town</h3>
                          <div class='property-card-extras'>
                            <div class='card-location'>Ogun State</div>
                          </div>
                        </div>
                        <div className='project-logo'>
                          <img src={devLogo} alt='' />
                        </div>
                        <div class='card-bed-bath-holder'>
                          <div class='card-inner-side'>
                            <div class='card-icon'></div>
                            <div class='card-bed-bath'>2 Beds</div>
                          </div>
                          <div class='card-inner-divider'></div>
                          <div class='card-inner-side'>
                            <div class='card-icon'></div>
                            <div class='card-bed-bath'>2 Baths</div>
                          </div>
                          <div class='card-inner-divider'></div>
                          <div class='card-inner-side'>
                            <div class='card-icon'></div>
                            <div class='card-bed-bath'>650 Sqm</div>
                          </div>
                        </div>
                        <div class='mortgage-deets-holder'>
                          <div class='card-inner-block'>
                            <p class='mortgage-heading'>Buy on a mortgage</p>
                            <div class='mortgage-wrapper'>
                              <p class='mortage-value'>₦30,000</p>
                              <div class='mortgage-freq'>/month</div>
                            </div>
                          </div>
                          <div class='card-inner-divider long'></div>
                          <div class='card-inner-block'>
                            <p class='repayment-heading'>Payback over</p>
                            <div class='repayment-wrapper'>
                              <p class='repayment-period'>20</p>
                              <div class='repayment-freq'>/Years</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-4 mt-3'>
                    <div class='property-card'>
                      <div
                        class='property-image-holder'
                        style={{
                          backgroundImage: `url(${agbaraIgbesa})`,
                        }}
                      ></div>
                      <div class='property-deets'>
                        <div class='card-price-holder'>
                          <div class='card-property-status text-uppercase'>
                            Residential
                          </div>
                          <h4 class='card-price'>₦25,000,000</h4>
                        </div>
                        <div class='card-name-holder'>
                          <h3 class='card-title'>
                            Agbara/Igbesa Industrial Estate
                          </h3>
                          <div class='property-card-extras'>
                            <div class='card-location'>Ogun State</div>
                          </div>
                        </div>
                        <div className='project-logo'>
                          <img src={devLogo} alt='' />
                        </div>
                        <div class='card-bed-bath-holder'>
                          <div class='card-inner-side'>
                            <div class='card-icon'></div>
                            <div class='card-bed-bath'>2 Beds</div>
                          </div>
                          <div class='card-inner-divider'></div>
                          <div class='card-inner-side'>
                            <div class='card-icon'></div>
                            <div class='card-bed-bath'>2 Baths</div>
                          </div>
                          <div class='card-inner-divider'></div>
                          <div class='card-inner-side'>
                            <div class='card-icon'></div>
                            <div class='card-bed-bath'>650 Sqm</div>
                          </div>
                        </div>
                        <div class='mortgage-deets-holder'>
                          <div class='card-inner-block'>
                            <p class='mortgage-heading'>Buy on a mortgage</p>
                            <div class='mortgage-wrapper'>
                              <p class='mortage-value'>₦30,000</p>
                              <div class='mortgage-freq'>/month</div>
                            </div>
                          </div>
                          <div class='card-inner-divider long'></div>
                          <div class='card-inner-block'>
                            <p class='repayment-heading'>Payback over</p>
                            <div class='repayment-wrapper'>
                              <p class='repayment-period'>20</p>
                              <div class='repayment-freq'>/Years</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-4 mt-3'>
                    <div class='property-card'>
                      <div
                        class='property-image-holder'
                        style={{
                          backgroundImage: `url(${makunWrapper})`,
                        }}
                      ></div>
                      <div class='property-deets'>
                        <div class='card-price-holder'>
                          <div class='card-property-status text-uppercase'>
                            Residential
                          </div>
                          <h4 class='card-price'>₦25,000,000</h4>
                        </div>
                        <div class='card-name-holder'>
                          <h3 class='card-title'>Opic Advantage + </h3>
                          <div class='property-card-extras'>
                            <div class='card-location'>Ogun State</div>
                          </div>
                        </div>
                        <div className='project-logo'>
                          <img src={devLogo} alt='' />
                        </div>
                        <div class='card-bed-bath-holder'>
                          <div class='card-inner-side'>
                            <div class='card-icon'></div>
                            <div class='card-bed-bath'>2 Beds</div>
                          </div>
                          <div class='card-inner-divider'></div>
                          <div class='card-inner-side'>
                            <div class='card-icon'></div>
                            <div class='card-bed-bath'>2 Baths</div>
                          </div>
                          <div class='card-inner-divider'></div>
                          <div class='card-inner-side'>
                            <div class='card-icon'></div>
                            <div class='card-bed-bath'>650 Sqm</div>
                          </div>
                        </div>
                        <div class='mortgage-deets-holder'>
                          <div class='card-inner-block'>
                            <p class='mortgage-heading'>Buy on a mortgage</p>
                            <div class='mortgage-wrapper'>
                              <p class='mortage-value'>₦30,000</p>
                              <div class='mortgage-freq'>/month</div>
                            </div>
                          </div>
                          <div class='card-inner-divider long'></div>
                          <div class='card-inner-block'>
                            <p class='repayment-heading'>Payback over</p>
                            <div class='repayment-wrapper'>
                              <p class='repayment-period'>20</p>
                              <div class='repayment-freq'>/Years</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-4 mt-3'>
                    <div className='project-view-more'>
                      <div className='project-view-more-img'>
                        <img src={officeTowerImg} alt='' />
                      </div>
                      <h2>Projects In Ogun State</h2>
                      <p>
                        Our list of the best residential, corporate and
                        commercial projects from Lorem Ipsum is simply dummy
                        text of the printing and typesetting industry. Lorem
                        Ipsum has been the industry's standard dummy text.
                      </p>
                      <div className='view-more-btn'>
                        <a href='/find-property'>View All</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <section id='our-projects'>
            <div className='container px-0'>
              <div className='our-projects-wrapper'>
                <div className='projects-list'>
                  <a>
                    <div className='projects-list-wrapper'>
                      <div className='projects-img'>
                        <img src={makunWrapper} alt='Makun City' />
                      </div>
                      <div className='projects-type'>Residential</div>
                      <div className='project-border-hover'></div>
                    </div>
                    <div className='projects-content'>
                      <div className='project-logo'>
                        <img src={devLogo} alt='' />
                      </div>
                      <div className='project-info'>
                        <div className='project-name'>New Makun City</div>
                        <div className='project-location'>
                          <Icons.MapPin
                            size='20px'
                            color='#b1b1b1'
                            className='mr-2'
                          />
                          Makun City, Ogun State.
                        </div>
                        <div className='card-bed-bath-holder no-border'>
                          <div className='card-inner-side'>
                            <div className='card-icon'></div>
                            <div className='card-bed-bath'>3 Beds</div>
                          </div>
                          <div className='card-inner-divider'></div>
                          <div className='card-inner-side'>
                            <div className='card-icon'></div>
                            <div className='card-bed-bath'>3 Baths</div>
                          </div>
                          <div className='card-inner-divider'></div>
                          <div className='card-inner-side'>
                            <div className='card-icon'></div>
                            <div className='card-bed-bath'>0 Sqm</div>
                          </div>
                        </div>
                      </div>
                      <div className='project-price'>
                        <p>₦26,000,000</p>
                        <Link to='' className='view-more-cta'>
                          See Details
                        </Link>
                      </div>
                    </div>
                  </a>
                </div>

                <div className='projects-list'>
                  <a>
                    <div className='projects-list-wrapper'>
                      <div className='projects-img'>
                        <img src={makunWrapper} alt='Makun City' />
                      </div>
                      <div className='projects-type'>Residential</div>
                      <div className='project-border-hover'></div>
                    </div>
                    <div className='projects-content'>
                      <div className='project-logo'>
                        <img src={devLogo} alt='' />
                      </div>
                      <div className='project-info'>
                        <div className='project-name'>New Makun City</div>
                        <div className='project-location'>
                          <Icons.MapPin
                            size='20px'
                            color='#b1b1b1'
                            className='mr-2'
                          />
                          Makun City, Ogun State.
                        </div>
                        <div className='card-bed-bath-holder no-border'>
                          <div className='card-inner-side'>
                            <div className='card-icon'></div>
                            <div className='card-bed-bath'>3 Beds</div>
                          </div>
                          <div className='card-inner-divider'></div>
                          <div className='card-inner-side'>
                            <div className='card-icon'></div>
                            <div className='card-bed-bath'>3 Baths</div>
                          </div>
                          <div className='card-inner-divider'></div>
                          <div className='card-inner-side'>
                            <div className='card-icon'></div>
                            <div className='card-bed-bath'>0 Sqm</div>
                          </div>
                        </div>
                      </div>
                      <div className='project-price'>
                        <p>₦26,000,000</p>
                        <Link to='' className='view-more-cta'>
                          See Details
                        </Link>
                      </div>
                    </div>
                  </a>
                </div>

                <div className='projects-list'>
                  <a>
                    <div className='projects-list-wrapper'>
                      <div className='projects-img'>
                        <img src={makunWrapper} alt='Makun City' />
                      </div>
                      <div className='projects-type'>Residential</div>
                      <div className='project-border-hover'></div>
                    </div>
                    <div className='projects-content'>
                      <div className='project-logo'>
                        <img src={devLogo} alt='' />
                      </div>
                      <div className='project-info'>
                        <div className='project-name'>New Makun City</div>
                        <div className='project-location'>
                          <Icons.MapPin
                            size='20px'
                            color='#b1b1b1'
                            className='mr-2'
                          />
                          Makun City, Ogun State.
                        </div>
                        <div className='card-bed-bath-holder no-border'>
                          <div className='card-inner-side'>
                            <div className='card-icon'></div>
                            <div className='card-bed-bath'>3 Beds</div>
                          </div>
                          <div className='card-inner-divider'></div>
                          <div className='card-inner-side'>
                            <div className='card-icon'></div>
                            <div className='card-bed-bath'>3 Baths</div>
                          </div>
                          <div className='card-inner-divider'></div>
                          <div className='card-inner-side'>
                            <div className='card-icon'></div>
                            <div className='card-bed-bath'>0 Sqm</div>
                          </div>
                        </div>
                      </div>
                      <div className='project-price'>
                        <p>₦26,000,000</p>
                        <Link to='' className='view-more-cta'>
                          See Details
                        </Link>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section> */}

          <section id='news-events'>
            <div className='container'>
              <div className='news-events-header-text'>
                <h2>
                  News and
                  <br />
                  Updates
                </h2>
              </div>
              <div className='row'>
                <div className='col-md-4'>
                  <div className='news-events-wrapper'>
                    <div className='news-event-photo'>
                      <img className='img-fluid' src={blogSample3} alt='' />
                      <div className='date-box'>
                        <span>17</span>
                        Feb
                      </div>
                    </div>
                    <div className='new-events-wrapper-text'>
                      <h4>
                        <a href=''>Find Your Dream Real Estate</a>
                      </h4>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the dummy
                        text ever since the 1500s, when Lorem Ipsum is simply
                        dummy text of the printing and typesetting industry.....
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='news-events-wrapper'>
                    <div className='news-event-photo'>
                      <img className='img-fluid' src={blogSample3} alt='' />
                      <div className='date-box'>
                        <span>17</span>
                        Feb
                      </div>
                    </div>
                    <div className='new-events-wrapper-text'>
                      <h4>
                        <a href=''>Find Your Dream Real Estate</a>
                      </h4>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the dummy
                        text ever since the 1500s, when Lorem Ipsum is simply
                        dummy text of the printing and typesetting industry.....
                      </p>
                    </div>
                  </div>
                </div>

                <div className='col-md-4'>
                  <div className='news-events-wrapper'>
                    <div className='news-event-photo'>
                      <img className='img-fluid' src={blogSample3} alt='' />
                      <div className='date-box'>
                        <span>17</span>
                        Feb
                      </div>
                    </div>
                    <div className='new-events-wrapper-text'>
                      <h4>
                        <a href=''>Find Your Dream Real Estate</a>
                      </h4>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the dummy
                        text ever since the 1500s, when Lorem Ipsum is simply
                        dummy text of the printing and typesetting industry.....
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <section className='project-section'>
            <h2 id='project-sec-heading' className='site-heading sec'>
              Our
              <br />
              Projects
            </h2>
            <p id='' className='section-desc'>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry&#x27;s standard dummy
              text ever since the 1500s, when an unknown printer took a galley
              of type and scrambled it to make a type specimen book.
            </p>
            <PropertyList properties={this.state.properties} />
          </section> */}
        </div>
        <FooterPage />
      </div>
    );
  }
}

export default HomePage;
