import React, { Component } from "react";
import { Helmet } from "react-helmet";

import FooterPage from "../../components/FooterPage";

// import HomeFinanceWidget from "../sitemaps/HomeFinanceWidget";

export default class HomeFinance extends Component {
  render() {
    return (
      <div className="container-fluid p-0">
        <Helmet>
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <title>Home Finance | OPIC | Expanding the frontiers of Ogun State</title>
          <link rel="canonical" href="https://opicgroup.com/home-finance" />
        </Helmet>
        <section id='home-finance-widget'>
          <financeplus-widget
            developer='opic'
            has-property='false'
            url='https://app.newhomes.ng/api/developerapp/opic/not-paginated-all-properties'
            primary-color='#136b3d'
            font='DM Sans, DM Serif Display, sans-serif, Roboto'
            property-value=''
          ></financeplus-widget>
        </section>
        <FooterPage />
      </div>
    );
  }
}
