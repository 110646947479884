import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

import portfolioIcon from "../../resources/icons-04.png";
import eligibilityIcon from "../../resources/icons-01.png";
import dreamIcon from "../../resources/icons-02.png";

import FooterPage from "../../components/FooterPage";

class HomePurchase extends Component {
  defaultState = {
    outrightPurchaseTab: false,
    installmentPlanTab: false,
    mortgageOptionTab: false,
  };
  state = {...this.defaultState};

  OutrightPurchaseTabClick = (e) => {
    e.preventDefault();
    this.setState({
      outrightPurchaseTab: true,
      installmentPlanTab: false,
      mortgageOptionTab: false,
    });
  };

  InstallmentPlanTabClick = (e) => {
    e.preventDefault();
    this.setState({
      outrightPurchaseTab: false,
      installmentPlanTab: true,
      mortgageOptionTab: false,
    });
  };

  MortgageOptionTabClick = (e) => {
    e.preventDefault();
    this.setState({
      outrightPurchaseTab: false,
      installmentPlanTab: false,
      mortgageOptionTab: true,
    });
  };
  render() {
    return (
      <div className='container-fluid p-0'>
        <Helmet>
          <meta charset='utf-8' />
          <meta name='viewport' content='width=device-width, initial-scale=1' />
          <title>
            Home Purchase | OPIC - Expanding the frontiers of Ogun State
          </title>
          <link rel='canonical' href='/' />
        </Helmet>
        <section className='advantage-section'>
          <h2 id='' className='site-heading-adv'>
            Own a home in 3 easy steps
          </h2>
          <div id='' className='step-count'>
            <h3 className='step-count-items'>1</h3>
            <h3 className='step-count-items'>2</h3>
            <h3 className='step-count-items'>3</h3>
            <div className='hr-line'></div>
          </div>
          <div id='' className='steps'>
            <div className='step-wrapper mid'>
              <img
                src={eligibilityIcon}
                sizes='(max-width: 479px) 50vw, (max-width: 767px) 36vw, (max-width: 991px) 173.796875px, 7vw'
                alt=''
                className='step-icon'
              />
              <div className='step-details'>
                <h3 className='step-heading'>
                  Check your mortgage eligibility status &amp; apply online
                </h3>
                <p className='step-description'>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry&#x27;s
                  standard dummy text ever since the 1500s, when an unknown
                  printer took
                </p>
              </div>
            </div>

            <div className='step-wrapper mid'>
              <img
                src={portfolioIcon}
                sizes='(max-width: 479px) 50vw, (max-width: 767px) 36vw, (max-width: 991px) 173.796875px, 20vw'
                alt=''
                className='step-icon'
              />
              <div className='step-details'>
                <h3 className='step-heading'>
                  Choose from our long list of amazing homes
                </h3>
                <p className='step-description'>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry&#x27;s
                  standard dummy text ever since the 1500s, when an unknown
                  printer took
                </p>
              </div>
            </div>

            <div className='step-wrapper'>
              <img
                src={dreamIcon}
                sizes='(max-width: 479px) 50vw, (max-width: 767px) 36vw, (max-width: 991px) 174.484375px, 6vw'
                alt=''
                className='step-icon'
              />
              <div className='step-details'>
                <h3 className='step-heading'>Move into your dream home</h3>
                <p className='step-description'>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry&#x27;s
                  standard dummy text ever since the 1500s, when an unknown
                  printer took
                </p>
              </div>
            </div>
            <div id='' className='dual-cta-wrapper advantage'>
              <Link to='/find-property' className='site-button o-button'>
                See our projects
              </Link>
              <a
                data-toggle='modal'
                data-target='#exampleModalScrollable'
                className='site-button alt o-button'
              >
                home loan Eligibility
              </a>
            </div>
          </div>
        </section>

        <section id='opic-faqs'>
          <div className='container'>
            <div className='opic-faqs-wrapper'>
              <ul>
                <li>
                  <input type='checkbox' />
                  <i></i>
                  <h2>
                    What is the process in the acquisition of OPIC property?
                  </h2>
                  <p>
                    <strong>Step 1:</strong> Purchase Property Application form
                    from OPIC office at N50,000 payable via a bank draft or
                    online.
                  </p>
                  <p>
                    <strong>Step 2:</strong> Return filled form to the office
                    with a bank draft for property, for payment plan return with
                    a letter requesting specified payment option.
                  </p>
                  <p>
                    <strong>Step 3:</strong> Receive letter of allocation from
                    OPIC.
                    <br />
                    <span className='text-danger'>
                      *Steps differ for buyers through payment option as
                      physical meetings and verifications will be required.
                    </span>
                  </p>
                </li>
                <li>
                  <input type='checkbox' />
                  <i></i>
                  <h2>
                    Are there hidden charges when I buy property from OPIC?
                  </h2>
                  <p>
                    After paying for property, the only other charge is the
                    administrative charge which is 10% of property cost. The
                    Charge covers for VAT, and Ogun State Levy fees.
                  </p>
                </li>
                <li>
                  <input type='checkbox' />
                  <i></i>
                  <h2>
                    Does OPIC Advantage and OPIC Mortgage mean I must complete
                    payment between 3 years or in 20 years?
                  </h2>
                  <p>
                    No, flexible payment terms are available that can be
                    designed to fit your needs.
                  </p>
                </li>

                <li>
                  <input type='checkbox' />
                  <i></i>
                  <h2>Are Co-operative sales packages available?</h2>
                  <p>
                    Yes, it is possible to get a co-operative package for
                    communities, institutions or organizations?
                  </p>
                </li>

                <li>
                  <input type='checkbox' />
                  <i></i>
                  <h2>
                    Is it possible to reserve a house before making financial
                    commitment?
                  </h2>
                  <p>
                    No, houses cannot be reserved without a financial
                    commitment. 20% initial deposit must be made for OPIC
                    Advantage and OPIC Mortgage.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </section>

        {/* Modal  */}
        <div
          class='modal fade'
          id='exampleModalScrollable'
          tabindex='-1'
          role='dialog'
          aria-labelledby='exampleModalScrollableTitle'
          aria-hidden='true'
        >
          <div
            class='modal-dialog modal-lg modal-dialog-centered'
            role='document'
          >
            <div class='modal-content'>
              <div class='modal-header'>
                <h5 class='modal-title'>Payment Options</h5>
                <button
                  type='button'
                  class='close'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div class='modal-body'>
                <div className='info-section' style={{padding: "10px 30px"}}>
                  <div className='description-wrapper'>
                    {/* <h2 className='info-section-title'>Purchase Options</h2> */}
                    <div className='info-description'>
                      Looking to buy this property? Select your preferred
                      mortgage option below.
                    </div>
                    <div className='purchase-option-wrapper'>
                      <div className='purchase-option-toggle-wrapper'>
                        <a
                          className={
                            this.state.outrightPurchaseTab
                              ? "purchase-option-toggle o-inline-block current"
                              : "purchase-option-toggle o-inline-block"
                          }
                          onClick={this.OutrightPurchaseTabClick}
                        >
                          <div className='purchase-option-icon'></div>
                          <h2 className='option-name'>Outright purchase</h2>
                        </a>
                        <a
                          className={
                            this.state.installmentPlanTab
                              ? "purchase-option-toggle o-inline-block current"
                              : "purchase-option-toggle o-inline-block"
                          }
                          onClick={this.InstallmentPlanTabClick}
                        >
                          <div className='purchase-option-icon'></div>
                          <h2 className='option-name'>Installment plan</h2>
                        </a>
                        <a
                          className={
                            this.state.mortgageOptionTab
                              ? "purchase-option-toggle o-inline-block current"
                              : "purchase-option-toggle o-inline-block"
                          }
                          onClick={this.MortgageOptionTabClick}
                        >
                          <div className='purchase-option-icon'></div>
                          <h2 className='option-name'>Mortgage option</h2>
                        </a>
                      </div>

                      <div
                        className='mortgage-widget-wrapper o-form'
                        style={{
                          display: this.state.outrightPurchaseTab ? "" : "none",
                        }}
                      >
                        <p className='m-4'>
                          This is a payment plan that allows for payment upfront
                          or within a period of 6 months.
                        </p>
                      </div>

                      <div
                        className='installment-form-wrapper o-form'
                        style={{
                          display: this.state.installmentPlanTab ? "" : "none",
                        }}
                      >
                        <p className='m-4'>
                          Payment can be spread over a period of 3 to 20 years.
                          Payment plans available are;
                        </p>
                        <ul>
                          <li>
                            <strong> - OPIC Advantage: </strong> This is a
                            payment plan that allows for payment within a
                            maximum of 3 years at a 5% premium.
                          </li>
                          <li>
                            <strong>- OPIC Advantage+:</strong> This payment
                            plan is in conjuction with mortgage banking partners
                            and allows a qualified buyer pay over a period of 5
                            years at attractive terms.
                          </li>
                          <li>
                            <strong>- OPIC Mortgage:</strong> This is a payment
                            plan carried out in partnership with mortgage
                            banking partners and allows a qualified buyer pay
                            over a 20-year period on attractive terms
                          </li>
                        </ul>
                      </div>

                      <div
                        className='mortgage-widget-wrapper'
                        style={{
                          display: this.state.mortgageOptionTab ? "" : "none",
                        }}
                      >
                        <p className='m-4'>
                          This is a payment plan that allows for payment upfront
                          or within a period of 6 months.
                        </p>
                        <div id='' className='dual-cta-wrapper advantage'>
                          <a
                            href='/home-finance'
                            className='site-button o-button mortgage-cta'
                          >
                            Apply for mortgage
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterPage />
      </div>
    );
  }
}
export default HomePurchase;
