import React from 'react';
import { connect } from 'react-redux';

import './spinner.css';


const Spinner = ({ isLoading, size, color }) => {
  const spinnerColor = color ? color : '#000000';
  return isLoading ? (
    <div
    className="spinner-wrapper d-flex justify-content-center align-items-center cover-full"
    >
      <div
        role='status'
        className="spinner-border"
        style={{ width: size, height: size, color: spinnerColor }}
      >
        <span className="sr-only"></span>
      </div>
    </div>
  ) : "";
}

const mapStateToProps = ({ isLoading }, ownProps) => ({ isLoading, ...ownProps });

export default connect(mapStateToProps)(Spinner);