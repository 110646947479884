import React from "react";
import {Switch, Route, BrowserRouter} from "react-router-dom";
import SiteMapRoutes from "./sitemaps/";

import DashboardRoutes from "./dashboard";
import Error404Page from "../components/Error404Page";
import AuthRoutes from "./auth";

import ScrollToTop from "./sitemaps/ScrollToTop";

const Routes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Switch>
          <Route path='/auth' component={AuthRoutes} />
          <Route path='/admin' component={DashboardRoutes} />
          <Route path='/' component={SiteMapRoutes} />
          <Route component={Error404Page} />
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default Routes;
