import React from 'react';
import { getInputErrorElement } from '../../utils/formUtils';
import { requireFieldErrorMsg } from '../../utils/errorMessageUtils';
import './PropertyTypeListItem.css';


const PropertyTypeListItem = ({ property, statuses, propertyTypes, setHomeTypePriceItem, removeItem }) => {
  const handleChange = ({ target: { name, value } }) => setHomeTypePriceItem({ ...property, [name]: value });
  return (
    <div className="row hometype-item mt-3 pb-3">
      <div className="col-md-12 mb-2 row d-flex justify-content-end">
        <button type='button' className='btn btn-danger' onClick={removeItem}>-</button>
      </div>
      <div className="col-md-12 row">
      <div className="col-sm-12 col-md-3 mt-2">
        <div className="input-group d-flex flex-column">
          <label className='text-capitalize'>hometype</label>
          <select name='hometype' className='form-control' value={property.hometype || ''} required onChange={handleChange}>
            <option value='' defaultValue>-- Select a property type --</option>
            {
              propertyTypes.map(({ id, name }) => (
                <option value={id} key={id}>{name}</option>
              ))
            }
          </select>
          {getInputErrorElement()}
        </div>
      </div>
      <div className="col-sm-12 col-md-3 mt-2">
        <div className="input-group d-flex flex-column">
          <label className='text-capitalize'>no. <span className='text-lowercase'>of</span> bedrooms</label>
          <input type="number" name='bedrooms' value={property.bedrooms || ''} className='form-control' onChange={handleChange} required />
          {getInputErrorElement()}
        </div>
      </div>
      <div className="col-sm-12 col-md-3 mt-2">
        <div className="input-group d-flex flex-column">
          <label className='text-capitalize'>no. <span className='text-lowercase'>of</span> bathrooms</label>
          <input type="number" name='bathrooms' value={property.bathrooms || ''} className='form-control' onChange={handleChange} required />
          {getInputErrorElement()}
        </div>
      </div>
      <div className="col-sm-12 col-md-3 mt-2">
        <div className="input-group d-flex flex-column">
          <label className='text-capitalize'>no. <span className='text-lowercase'>of</span> garages</label>
          <input type="number" name='garages' value={property.garages || ''} className='form-control' onChange={handleChange} required />
          {getInputErrorElement()}
        </div>
      </div>
      <div className="col-sm-12 col-md-4 mt-2">
        <div className="input-group d-flex flex-column">
          <label className='text-capitalize'>property price</label>
          <input type="text" name='price' value={property.price || ''} className='form-control' onChange={handleChange} required />
          {getInputErrorElement()}
        </div>
      </div>
      <div className="col-sm-12 col-md-4 mt-2">
        <div className="input-group d-flex flex-column">
          <label className='text-capitalize'>property status</label>
          <select className='form-control' value={property.status || ''} name='status' onChange={handleChange} required>
            <option defaultValue value=''>-- Select a property status --</option>
            {
              statuses.map(({ id, name }) => (
                <option value={id} key={id}>{name}</option>
              ))
            }
          </select>
          {getInputErrorElement()}
        </div>
      </div>
      <div className="col-sm-12 col-md-4 mt-2">
        <div className="input-group d-flex flex-column">
          <label className='text-capitalize'>sold</label>
          <select className='form-control' value={property.is_sold || ''} name='is_sold' onChange={handleChange} required>
            <option value=''>-- Select an answer --</option>
            <option value='1'>Yes</option>
            <option value='0'>No</option>
          </select>
          {getInputErrorElement()}
        </div>
      </div>
      </div>
    </div>
  );
}
 
export default PropertyTypeListItem;