import React, { useRef, useState, useEffect } from "react";
import { Eye, EyeOff } from 'react-feather';

import { getInputErrorElement, validateForm, getReplaceErrorElement } from "../../utils/formUtils";
import { invalidPasswordErrorMsg } from "../../utils/errorMessageUtils";
import http from "../../config/axiosConfig";


const VerityCodeForm = ({ email, history, setHasSentEmail }) => {
  const inputRefs = [
    useRef(null), useRef(null), useRef(null),
    useRef(null), useRef(null), useRef(null)
  ];

  useEffect(() => {
    document.title = "Email Verification  | Lagos State Government";
  });

  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [hidden, setHidden] = useState(true);

  const toggleShow = () => setHidden(!hidden);

  const handleCodeKeyDown = ({ key, target: { dataset: { index: indexString } } }) => {
    const index = parseInt(indexString, 10);
    const splitCode = code.split('');
    const num = parseInt(key, 10);
    if (key === 'Backspace') {
      if (splitCode[index]) {
        splitCode[index] = '';
        setCode(splitCode.join(''));
      } else if (index) inputRefs[index - 1].current.focus();
    } else if (!isNaN(num)) {
      splitCode[index] = key;
      setCode(splitCode.join(''));
      if (index < 5) inputRefs[index + 1].current.focus();
    }
  };

  const handleSubmit = async (event) => {
    const invalidInput = validateForm(event);
    if (invalidInput) return invalidInput.focus();
    try {
      await http.post('/auth/change-password-via-code', { email, code, password });
      history.push('/auth/login');
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className='lagos-state-app-auth-form'>
      <form noValidate onSubmit={handleSubmit}>
        <div className='form-group row mb-4'>
          <div className='col-md-12'>
            <input
              type={hidden ? "password" : "text"}
              name='password'
              value={password}
              onChange={({ target: { value } }) => setPassword(value)}
              className='form-control'
              minLength='6'
              placeholder='Password'
              autoComplete='current-password'
              required
            />
            <div className='password-show-hide'>
              {hidden ? (
                <Eye onClick={toggleShow} size='20px' />
              ) : (
                <EyeOff onClick={toggleShow} size='20px' />
              )}
            </div>
            {getInputErrorElement(invalidPasswordErrorMsg)}
          </div>
        </div>
        <div className='form-group row'>
          <div className='col-md-12'>
            <div className='code_group'>
              <input
                ref={inputRefs[0]}
                type='number'
                maxLength='1'
                data-index='0'
                value={code[0] || ''}
                onKeyDown={handleCodeKeyDown}
                placeholder='0'
                required
              />
              <input
                ref={inputRefs[1]}
                type='number'
                maxLength='1'
                data-index='1'
                value={code[1] || ''}
                onKeyDown={handleCodeKeyDown}
                placeholder='0'
                required
              />
              <input
                ref={inputRefs[2]}
                type='number'
                maxLength='1'
                data-index='2'
                value={code[2] || ''}
                onKeyDown={handleCodeKeyDown}
                placeholder='0'
                required
              />
              <input
                ref={inputRefs[3]}
                type='number'
                maxLength='1'
                data-index='3'
                value={code[3] || ''}
                onKeyDown={handleCodeKeyDown}
                placeholder='0'
                required
              />
              <input
                ref={inputRefs[4]}
                type='number'
                maxLength='1'
                data-index='4'
                value={code[4] || ''}
                onKeyDown={handleCodeKeyDown}
                placeholder='0'
                required
              />
              <input
                ref={inputRefs[5]}
                type='number'
                maxLength='1'
                data-index='5'
                value={code[5] || ''}
                onKeyDown={handleCodeKeyDown}
                placeholder='0'
                required
              />
            </div>
            {
              code ? '' : getReplaceErrorElement()
            }
          </div>
        </div>

        <div className='form-group row lagos-state-app-auth-reset-password'>
          <div className='col-md-12'>
            <button
              className='lagos-state-app-auth-reset-password-btn'
              type='submit'
            >
              Verify Code
            </button>

            <p className='lagos-state-app-auth-account-holder'>
              You didn't receive any code?
              <span onClick={() => setHasSentEmail(false)}>Request Code</span>
            </p>
          </div>
        </div>
      </form>
    </div>
  );
};
 
export default VerityCodeForm;
