import React, { Component } from 'react';

import './ErrorPage.css';


class ErrorPage extends Component {
  state = { hasError: false, errorCode: '', errorMessage: '' }

  static getDerivedStateFromError(error) {
    console.log(error, error.status, error.message);
    const errorCode = error.status;
    const errorMessage = error.message;
    return { hasError: true, errorCode, errorMessage };
  }

  render() {
    const { hasError, errorCode, errorMessage } = this.state;
    return hasError ? (
      <div className='error-page-wrapper d-flex flex-column justify-content-center align-items-center'>
        <h3 className='text-center mb-2'>Something went wrong{ errorCode ? ': ' + errorCode : ''}</h3>
        <p className='text-center'>{errorMessage}</p>
      </div>
    ) : this.props.children;
  }
}
 
export default ErrorPage;