import React, {Component} from "react";
import {Helmet} from "react-helmet";

// import HeaderPageHeader from "../../components/HeaderPage/HomePageHeader";
import FooterPage from "../../components/FooterPage";
import ArchiveList from "../../modules/sitemaps/ArchiveList";
import PropertyFilter from "../../modules/sitemaps/PropertyFilter";
import setProperties from '../../store/actions/setProperties';

import "./SiteMap.css";
import http, { httpWithoutLoader } from "../../config/axiosConfig";
import { connect } from "react-redux";
import PropertyFilterForm from "./PropertyFilterForm";
import { removeCommas } from "../../utils/stringUtils";
import setLoading from "../../store/actions/setLoading";

class FindProperty extends Component {
  defaultState = {
    properties: [],
    filter: {},
    location: "",
    bed: "",
    bath: "",
    maxprice: ""
  };

  filterList = [];

  // applyFilter = (listings, filter) => {
  //   const {location, bed, bath, maxprice} = filter;
  // };

  state = {...this.defaultState};

  getProperty = async () => {
    try {
      const res = await http.get("/all-properties");
      this.filterList = res.data;
      this.setState({
        properties: res.data.data
      });
    } catch (error) {}
  };

  populateStoreProperties = async () => {
    const { setProperties } = this.props;
    try {
      const { data } = await httpWithoutLoader.get('/not-paginated-all-properties');
      setProperties(data);      
    } catch (error) {}
  };

  async componentDidMount() {
    await Promise.all([this.getProperty(), this.populateStoreProperties()]);
    if (this.props.location.search) this.filterProperties();
  };

  componentDidUpdate({ location: { search: prevSearch } }) {
    const { location: { search } } = this.props;
    if (prevSearch !== search) {
      if (!search) this.getProperty();
      else this.filterProperties();
    }
  };

  filterProperties = () => {
    const { location: { search }, properties, setLoading } = this.props;
    const searchMapper = {};
    search.slice(1).split('&').forEach(el => {
      const [k, v] = el.split('=');
      searchMapper[k] = v;
    });
    if (Object.values(searchMapper).join('')) {
      setLoading(true);
      const timeout = setTimeout(() => {
        setLoading(false);
        clearTimeout(timeout);
      },  1000);
      const filteredProperties = properties.filter((property) => {
        const {
          property_city, property_price, property_bedrooms, property_bathrooms
        } = property;

        return parseFloat(removeCommas(searchMapper.price) || '0') <= parseFloat(removeCommas(property_price))  
          && searchMapper.bedrooms === property_bedrooms && searchMapper.bathrooms === property_bathrooms
          && property_city.toLowerCase().includes((searchMapper.location.toLowerCase()));
      });
      
      this.setState({ properties: filteredProperties });
    }
  };

  render() {
    return (
      <div className='page-wrapper'>
        <Helmet>
          <meta charset='utf-8' />
          <meta name='viewport' content='width=device-width, initial-scale=1' />
          <title>
            Find Property | OPIC - Expanding the frontiers of Ogun State
          </title>
          <link rel='canonical' href='https://opicgroup.com/find-property' />
        </Helmet>

        <div id='' className='sorting-bar o-form'>
          <PropertyFilterForm {...{ history: this.props.history }} />
          <div className='o-form-done'>
            <div>Thank you! Your submission has been received!</div>
          </div>
          <div className='o-form-fail'>
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div>

        {/* <PropertyFilter /> */}
        <ArchiveList properties={this.state.properties} />
        <FooterPage />
      </div>
    );
  }
};

const mapStateToProps = ({ properties }, ownProps) => ({ properties, ...ownProps });

const mapDispatchToProps = (dispatch) => ({
  setProperties(properties) { dispatch(setProperties(properties)); },
  setLoading(isLoading) { dispatch(setLoading(isLoading)); }
});

export default connect(mapStateToProps, mapDispatchToProps)(FindProperty);
