import React, { useEffect, useState } from 'react';
import { invalidEmailErrorMsg, invalidPhoneErrorMsg } from '../../utils/errorMessageUtils';
import { getInputErrorElement, validateForm } from '../../utils/formUtils';
import BrowserStorage from '../../utils/browserStorageUtils';
import http from '../../config/axiosConfig';
import { logout } from '../../utils/authUtils';
import { emailRegexString, phoneRegexString } from '../../utils/regexUtils';


const ProfileUserDetailsForm = ({ history }) => {
  const [stateJSON, setStateJSON] = useState('{}');
  useEffect(() => {
    const {
      name, address, email, phone, slug, id
    } = BrowserStorage.getUserData();
    setStateJSON(JSON.stringify({ name, address, email, phone, slug, id }));
  }, []);

  const state = JSON.parse(stateJSON);
  const handleChange = ({ target: { name, value } }) => setStateJSON(JSON.stringify({ ...state, [name]: value }));

  const handleSubmit = async (event) => {
    const invalidInput = validateForm(event);
    if (invalidInput) return invalidInput.focus();

    try {
      await http.post(`/auth/profile-update/${state.id}`, state);
      logout(history);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form className="user-details col-sm-12 col-md-6 my-3" noValidate onSubmit={handleSubmit}>
      <fieldset className="form-group">
        <legend className='text-capitalize'>name</legend>
        <div className="input-group">
          <input
            type="text"
            name='name'
            value={state.name}
            onChange={handleChange}
            className='form-control'
            placeholder='Name'
            required
          />
          {getInputErrorElement()}
        </div>
      </fieldset>
      <fieldset className="form-group">
        <legend className='text-capitalize'>email</legend>
        <div className="input-group">
          <input
            type="email"
            name='email'
            value={state.email}
            onChange={handleChange}
            className='form-control'
            placeholder='user@example.com'
            pattern={emailRegexString}
            required
          />
          {getInputErrorElement(invalidEmailErrorMsg)}
        </div>
      </fieldset>
      <fieldset className="form-group">
        <legend className='text-capitalize'>address</legend>
        <div className="input-group">
          <input
            type="text"
            name='address'
            value={state.address}
            onChange={handleChange}
            className='form-control'
            placeholder='Address'
            required
          />
          {getInputErrorElement()}
        </div>
      </fieldset>
      <fieldset className="form-group">
        <legend className='text-capitalize'>phone</legend>
        <div className="input-group">
          <input
            type="text"
            name='phone'
            value={state.phone}
            onChange={handleChange}
            className='form-control'
            placeholder='Phone'
            pattern={phoneRegexString}
            required
          />
          {getInputErrorElement(invalidPhoneErrorMsg)}
        </div>
      </fieldset>
      <div className="form-group">
        <button type='submit' className='btn btn-primary text-uppercase'>update profile</button>
      </div>
    </form>
  );
}
 
export default ProfileUserDetailsForm;