import { USER_BROWSER_STORAGE_KEY, USER_TOKEN } from "./constants";

export default class BrowserStorage {
  static setUserData(userData) {
    localStorage.setItem(USER_BROWSER_STORAGE_KEY, JSON.stringify(userData));
  }

  static getUserData() {
    const userData = localStorage.getItem(USER_BROWSER_STORAGE_KEY);
    return JSON.parse(userData);
  }

  static setUserToken(token) {
    localStorage.setItem(USER_TOKEN, token);
  }

  static getUserToken() {
    return localStorage.getItem(USER_TOKEN);
  }

  static clearUserData() {
    localStorage.clear();
  }
};
