export const toTitleCase = (str) => str[0].toUpperCase() + str.slice(1);
export const normalizeConcatString = (str) => str.split(/[-_]/).map(word => word).join(' ');
export const removeCommas = str => str.replace(/,/g, "");

export const formatStringToCamelCase = (str) => {
  const splitted = str.split(' ');
  if (splitted.length === 1) return splitted[0];
  return splitted[0] + splitted.slice(1).map(word => word[0].toUpperCase() + word.slice(1)).join('');
};

export const formatMoneyValue = (value) => {
  const numValue = typeof value === 'string' ? parseFloat(value) : value;
  let [whole, decimal] = numValue.toFixed(2).split('.');
  return [parseInt((whole), 10).toLocaleString('en'), decimal].join('.');
};