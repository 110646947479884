import React from "react";
import {Link} from "react-router-dom";

import opicLogo from "../../resources/opic-logo-1.png";

const FooterPage = () => {
  return (
    <footer className='footer'>
      <div id='' className='footer-widget one'>
        <img
          className='footer-logo'
          src={opicLogo}
          width='120'
          alt='Opic Logo'
        />
        <div className='footer-social-wrapper'>
          <a href='#' className='footer-social-link'>
            
          </a>
          <a href='#' className='footer-social-link'>
            
          </a>
          <a href='#' className='footer-social-link'>
            
          </a>
          <a href='#' className='footer-social-link'>
            
          </a>
        </div>
      </div>
      <div id='' className='footer-widget two'>
        <h4 className='footer-widget-heading'>Our Portfolio</h4>
        <div className='footer-link-holder'>
          <Link to='/' className='footer-link'>
            Orange Valley Estate
          </Link>
          <Link to='/' className='footer-link'>
            MTR Gardens
          </Link>
          <Link to='/' className='footer-link'>
            OPIC Plaza Ikeja
          </Link>
          <Link to='/' className='footer-link'>
            MITROS Gateway Annex
          </Link>
          <Link to='/' className='footer-link'>
            New Dawn
          </Link>
        </div>
      </div>
      <div id='' className='footer-widget three'>
        <h4 className='footer-widget-heading'>Buy Property</h4>
        <div className='footer-link-holder'>
          <Link to='/' className='footer-link'>
            How it works
          </Link>
          <Link to='/home-finance' className='footer-link'>
            Home Finance
          </Link>
          <Link to='/' className='footer-link'>
            Opic Advantage
          </Link>
          <Link to='/' className='footer-link'>
            Careers
          </Link>
          <Link to='/frequently-asked-questions' className='footer-link'>
            FAQs
          </Link>
        </div>
      </div>
      <div id='' className='footer-widget four'>
        <h4 className='footer-widget-heading'>Contact Us</h4>
        <div className='footer-link-holder'>
          <Link to='/' className='footer-link'>
            Email: info@opicgroup.com
          </Link>
          <Link to='/' className='footer-link'>
            Phone: +234 811 289 2020
          </Link>
          <Link to='/' className='footer-link'>
            Phone 2: +234 811 289 2015
          </Link>
        </div>
      </div>
      <p id='' className='copyright'>
        &copy;2020 Ogun State Property and Investment Corporation. All Rights
        Reserved.
      </p>
    </footer>
  );
};
export default FooterPage;
