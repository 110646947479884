import React, {useState} from "react";
import "./Form.css";

import * as Icon from "react-feather";
import { emailRegexString } from '../../utils/regexUtils';
import { toTitleCase } from "../../utils/stringUtils";
import { validateForm, getInputErrorElement } from "../../utils/formUtils";
import http from "../../config/axiosConfig";
import { handleAuthenticationSuccess } from "../../utils/authUtils";
import { Link } from "react-router-dom";
import { invalidPasswordErrorMsg } from "../../utils/errorMessageUtils";


const LoginForm = ({ isLoading, history }) => {
  const [hidden, setHidden] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const setters = { setHidden, setEmail, setPassword };

  const handleInputChange = ({ target: { value, name } }) => {
    setters[`set${toTitleCase(name)}`](value);
  };

  const toggleShow = () => setHidden(!hidden);

  const state = { email, password };

  const handleSubmit = async (event) => {
    const invalidInput = validateForm(event);
    if (invalidInput) return invalidInput.focus();
    try {
      const { data } = await http.post('/auth/login', state);
      handleAuthenticationSuccess(data, history);
    } catch (error) {}
  };

  return (
    <div className='lagos-state-app-auth-form'>
      <form noValidate onSubmit={handleSubmit}>
        <div className='form-group row'>
          <div className='col-md-12'>
            <input
              type='text'
              name='email'
              value={email}
              className='form-control'
              placeholder='you@example.com'
              pattern={emailRegexString}
              onChange={handleInputChange}
              autoComplete='username'
              required
            />
            <div className="invalid-feedback">Invalid or empty email address</div>
          </div>
        </div>

        <div className='form-group row'>
          <div className='col-md-12'>
            <input
              type={hidden ? "password" : "text"}
              name='password'
              value={password}
              onChange={handleInputChange}
              className='form-control'
              minLength='6'
              placeholder='Password'
              autoComplete='current-password'
              required
            />
            <div className='password-show-hide'>
              {hidden ? (
                <Icon.Eye onClick={toggleShow} size='20px' />
              ) : (
                <Icon.EyeOff onClick={toggleShow} size='20px' />
              )}
            </div>
            {getInputErrorElement(invalidPasswordErrorMsg)}
          </div>
        </div>

        <div className='form-group row lagos-state-app-auth-submit'>
          <div className='col-md-7 lagos-state-app-auth-forgot-password'>
            <Link to='/auth/password/reset'>Forgot your password?</Link>
          </div>
          <div className='col-md-5'>
            <button type='submit' className='lagos-state-app-auth-submit-btn' disabled={isLoading} >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
 
export default LoginForm;
