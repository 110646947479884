import React from "react";
import { useEffect } from "react";
import {Route, Switch} from "react-router-dom";
import LoginPage from "./LoginPage";
import Error404Page from "../../components/Error404Page";
import ForgotPassword from '../../components/ForgotPassword';
import BrowserStorage from "../../utils/browserStorageUtils";
import ErrorNotification from '../../components/ErrorNotification';

const AuthRoutes = ({ history }) => {
  useEffect(() => {
    const token = BrowserStorage.getUserToken();
    if (token) history.replace('/admin');
  });
  
  return (
    <div>
      <ErrorNotification />
      <Switch>
        <Route exact path='/auth/login' component={LoginPage} />
        <Route exact path='/auth/password/reset' component={ForgotPassword} />
        <Route component={Error404Page} />
      </Switch>
    </div>
  );
};

export default AuthRoutes;
