import BrowserStorage from "./browserStorageUtils";
import http from "../config/axiosConfig";

export const handleAuthenticationSuccess = ({ token, user }, history) => {
  BrowserStorage.setUserData(user);
  BrowserStorage.setUserToken(token);
  http.defaults.headers = { Authorization: `Bearer ${token}` };
  const timeout = setTimeout(() => {
    history.replace('/admin');
    clearTimeout(timeout);
  }, 200);
};

export const logout = (history) => {
  BrowserStorage.clearUserData();
  http.defaults.headers = { Authorization : null };
  history.replace('/auth/login');
};