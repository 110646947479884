import React, { useEffect, useState } from 'react';

import '../PropertyList/PropertyList.css';
import DataTable from '../DataTable';
import http from '../../config/axiosConfig';


const UserList = ({ history }) => {
  const [usersJSON, setUsersJSON] = useState('[]');

  useEffect(() => {
    (async() => {
      try {
        const { data } = await http.get('/admin/all-users');
        setUsersJSON(JSON.stringify(data));
      } catch (error) {}
    })();
  }, [usersJSON]);

  const usersData = JSON.parse(usersJSON);

  const generateActions = (slug) => {
    const viewDetails = () => {};
    const editItem = () => history.push(`/admin/users/edit/${slug}`);
    const deleteItem = () => {};

    return { viewDetails, editItem, deleteItem };
  };

  return (
    <div className="property-list-page-wrapper">
      <h2 className='h6 font-weight-bold text-uppercase'>users</h2>
        <DataTable
          {...{
            generateActions,
            data: usersData,
            headings: [
              { key: "name", title: 'user name' },
              { key: "email", title: 'email' },
              { key: "phone", title: 'phone' },
              { key: 'created_at', title: 'created' },
            ]
          }}
        />
    </div>
  );
};

export default UserList;