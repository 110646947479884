import React, {Component} from "react";

export default class PropertyInfo extends Component {
  render() {
    return (
      <div className='info-section'>
        <div className='name-icon-wrapper'>
          <div className='name-icon-left'>
            <div className='card-property-status'>{this.props.status}</div>
            <h2 className='property-name'>{this.props.name}</h2>
            <div className='card-location'>{this.props.state}</div>
          </div>
          <div className='property-features-group'>
            <div className='property-features-wrapper'>
              <div className='property-info-icon-wrapper'>
                <div className='info-icon'></div>
                <div className='info-icon-name'>{this.props.bedroom} Beds</div>
              </div>
              <div className='property-info-icon-wrapper'>
                <div className='info-icon'></div>
                <div className='info-icon-name'>
                  {this.props.bathroom} Baths
                </div>
              </div>
              <div className='property-info-icon-wrapper'>
                <div className='info-icon'></div>
                <div className='info-icon-name'>{this.props.sqm} Cars</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
