import React, { useEffect, useState } from 'react';

import '../PropertyList/PropertyList.css';
import DataTable from '../DataTable';
import http, { httpWithoutLoader } from '../../config/axiosConfig';
import AddBuyerModal from '../AddBuyerModal';
import { useRef } from 'react';


const BuyerList = ({ history }) => {
  const addBuyerButton = useRef(null);
  const [buyersJSON, setBuyersJSON] = useState('[]');
  const [propertiesJSON, setPropertiesJSON] = useState('[]');
  useEffect(() => {
    (async() => {
      try {
        const [{ data }, { data: properties }] = await Promise.all([
          http.get('/admin/all-buyers'), httpWithoutLoader.get('/all-pluck-properties')
        ]);
        setBuyersJSON(JSON.stringify(data));
        setPropertiesJSON(JSON.stringify(properties));
      } catch (error) {}
    })();
  }, [buyersJSON]);

  const buyersData = JSON.parse(buyersJSON);
  const generateActions = (slug) => {
    const viewDetails = () => {};
    const editItem = () => history.push(`/admin/buyers/edit/${slug}`);
    const deleteItem = () => {};

    return { viewDetails, editItem, deleteItem };
  };

  return (
    <div className="property-list-page-wrapper">
      <div className='d-flex justify-content-between'>
        <h2 className='h6 font-weight-bold text-uppercase'>buyers</h2>
        <button
          ref={addBuyerButton}
          className="btn btn-primary text-capitalize"
          data-toggle="modal" data-target="#addBuyerModal"
          style={{ height: '40px' }}
        >
          add buyer
        </button>
        <AddBuyerModal {...{ properties: JSON.parse(propertiesJSON) }} />
      </div>
      
        <DataTable
          {...{
            generateActions,
            data: buyersData,
            headings: [
              { key: "name", title: 'user name' },
              { key: "email", title: 'email' },
              { key: "phone", title: 'phone' },
              { key: "property", title: 'property name', innerKey: 'property_name' },
              { key: "value", title: 'property value', isMoney: true },
              { key: 'created_at', title: 'created' },
            ]
          }}
        />
    </div>
  );
};

export default BuyerList;