import React from 'react';

import './PropertiesStatusOverview.css';


const PropertiesStatusOverview = ({ wrapperClassName, stats }) => {
  return (
    <div className='col-sm-12 col-md-6 mb-4 pl-3'>
      <div className={`properties-status-overview-wrapper${wrapperClassName ? ` ${wrapperClassName}` : ''}`}>
        <h5 className='text-capitalize'>property status overview</h5>
        <p>Daily information about statistics in the system</p>
    
        <div className="stats-wrapper text-center row d-flex justify-content-around pt-4">
          {
            stats.map(({ status_count, property_status }, index) => {
              return property_status === 'Off plan' ? '' : (
                <div className={`${property_status.toLowerCase().split(' ').join('-')}-stats col-md-4 col-sm-6 d-flex flex-column align-items-center mb-3`} key={index}>
                  <div className="indicator d-flex justify-content-center align-items-center mb-3">
                    <p className='h2'>{status_count}</p>
                  </div>
              
                  <p className='text-capitalize mt-2'>{property_status}</p>
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  );
}
 
export default PropertiesStatusOverview;