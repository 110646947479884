import React, {Component} from "react";

export default class PropertyTypes extends Component {
  renderPropertyType = () => {
    const mapPropertyType =
      this.props.types === undefined ? (
        <tr>
          <td>
            <div>No entries found</div>
          </td>
        </tr>
      ) : (
        this.props.types.map((type, i) => (
          <tr key={type.id} id={i}>
            <td>{type.hometype}</td>
            <td>&#8358;{new Intl.NumberFormat().format(type.price)}</td>
            <td>{type.bedrooms} Bedrooms</td>
            <td>{type.bathrooms} Bathrooms</td>
            <td>
              {type.is_sold === 0 ? (
                <span className='badge badge-danger'>Sold Out</span>
              ) : (
                <span className='badge badge-primary'>Available</span>
              )}
            </td>
          </tr>
        ))
      );
    return mapPropertyType;
  };

  render() {
    return (
      <div className='row'>
        <div className='col-xs-12'>
          <div className='table-responsive'>
            <table className='table table-bordered table-hover'>
              <thead>
                <tr>
                  <th>Types</th>
                  <th>Price</th>
                  <th>No Bedroom</th>
                  <th>No Bathroom</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>{this.renderPropertyType()}</tbody>
            </table>
          </div>
        </div>
      </div>
      // <div className='table-responsive'>
      //   <table className='table table-hover m-b-0'>
      //     <thead className='thead-light'>
      //       <tr>
      //         <th>Types</th>
      //         <th>Price</th>
      //         <th>No Bedroom</th>
      //         <th>No Bathroom</th>
      //         <th>Status</th>
      //       </tr>
      //     </thead>
      //     <tbody>{this.renderPropertyType()}</tbody>
      //   </table>
      // </div>
    );
  }
}
