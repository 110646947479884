import React from 'react';
import { connect } from 'react-redux';

import setErrors from '../../store/actions/setErrors';
import { toTitleCase } from '../../utils/stringUtils';
import './error-notification.css';


const ErrorNotification = ({ errors, clearError, top }) => {
  const isArray = errors && JSON.stringify(errors)[0] === '[';
  const listStyle = {};
  const navStyle = {};
  if (isArray && errors.length === 1) {
    listStyle.listStyleType = 'none';
    listStyle.paddingLeft = '24px';
  }
  if (top) {
    navStyle.top = top;
    navStyle.zIndex = 2;
  }

  return isArray && errors.length ? (
    <div className="error-notification-wrapper position-absolute d-flex container-fluid" style={navStyle}>
      <div className="alert-danger p-2 m-auto d-flex">
        <ul style={listStyle} className='mb-0 pt-1 pb-1'>
          {errors.map((error, index) => <li key={index}>{ toTitleCase(error) }</li>)}
        </ul>
        <span className='flex-end close' onClick={clearError}>&times;</span>
      </div>
    </div>
  ) : '';
}


const mapStateToProps = ({ errors }, ownProps) => ({ errors, ...ownProps });

const mapDispatchToProps = (dispatch) => ({
  clearError() { dispatch(setErrors([])); }
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorNotification);