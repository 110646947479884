import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";

import HeaderPage from "../../components/HeaderPage";
import SidebarPage from "../../components/SidebarPage";
import PropertyList from '../../components/PropertyList';
import './dashboard.css';
import Dashboard from "../../components/Dashboard";
import BrowserStorage from "../../utils/browserStorageUtils";
import setErrors from "../../store/actions/setErrors";
import { connect } from "react-redux";
import AddPropertyPage from "../../components/AddPropertyPage";
import ErrorNotification from "../../components/ErrorNotification";
import BuyerList from '../../components/BuyerList';
import ContractorList from '../../components/ContractorList';
import UserList from '../../components/UserList';
import AdminPropertyDetails from "../../components/AdminPropertyDetails.js";
import AdminProfile from "../../components/AdminProfile";

const DashboardRoutes = ({ history, setErrors }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = BrowserStorage.getUserToken();
    if (token) return setIsAuthenticated(true);
    
    setErrors(['Please log in to continue']);
    history.replace('/auth/login');
  });

  return isAuthenticated ? (
    <div id='wrapper'>
      <ErrorNotification top='80px' />
      <HeaderPage {...{history}} />
      <SidebarPage />
      <div className="dashboard-contents pt-5 pl-4 pr-4">
        <Switch>
          <Route exact path='/admin' component={Dashboard} />
          <Route exact path='/admin/properties' component={PropertyList} />
          <Route exact path='/admin/properties/add' component={AddPropertyPage} />
          <Route exact path='/admin/properties/add/:slug' component={AddPropertyPage} />
          <Route exact path='/admin/properties/edit/:slug' component={AddPropertyPage} />
          <Route exact path='/admin/users' component={UserList} />
          <Route exact path='/admin/contractors' component={ContractorList} />
          <Route exact path='/admin/buyers' component={BuyerList} />
          <Route exact path='/admin/properties/view/:slug' component={AdminPropertyDetails} />
          <Route exact path='/admin/profile' component={AdminProfile} />
        </Switch>
      </div>
    </div>
  ) : '';
};

const mapStateToProps = (_, ownProps) => ({ ...ownProps });

const mapDispatchToProps = (dispatch) => ({
  setErrors(errors) { dispatch(setErrors(errors)); }
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardRoutes);
