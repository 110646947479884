import React, {Component} from "react";

class PropertyFilter extends Component {
  defaultState = {
    location: "",
    bed: "",
    bath: "",
    maxprice: ""
  };
  state = {...this.defaultState};

  render() {
    return (
      <div id='' className='sorting-bar o-form'>
        <form
          id='wf-form-Site-Filter'
          name='wf-form-Site-Filter'
          method='post'
          action=''
          className='filter-form'
        >
          <div id='filter-name-location' className='filter-input-wrapper'>
            <div className='filter-input-icon'></div>
            <input
              id='name-location'
              type='text'
              className='filter-input o-input'
              maxLength='256'
              name='Name-or-Location'
              data-name='Name or Location'
              placeholder='Name, Neighborhood, City'
              value={this.state.location}
              onChange={e => this.setState({location: e.target.value})}
            />
          </div>
          <div id='filter-bedrooms' className='filter-input-wrapper'>
            <div className='filter-input-icon'></div>
            <input
              type='number'
              className='filter-input o-input'
              maxLength='10'
              name='Bedrooms'
              data-name='Bedrooms'
              placeholder='Bedrooms'
              id='Bedrooms'
              value={this.state.bed}
              onChange={e => this.setState({bed: e.target.value})}
            />
          </div>
          <div id='filter-bathrooms' className='filter-input-wrapper'>
            <div className='filter-input-icon'></div>
            <input
              type='number'
              className='filter-input o-input'
              maxLength='10'
              name='Bathrooms'
              data-name='Bathrooms'
              placeholder='Bathrooms'
              id='Bathrooms'
              value={this.state.bath}
              onChange={e => this.setState({bath: e.target.value})}
            />
          </div>
          <div id='filter-price' className='filter-input-wrapper'>
            <div className='filter-input-icon'></div>
            <input
              type='number'
              className='filter-input o-input'
              maxLength='256'
              name='Price'
              data-name='Price'
              placeholder='Maximum Price'
              id='Price'
              value={this.state.maxprice}
              onChange={e => this.setState({maxprice: e.target.value})}
            />
          </div>
          <input
            type='submit'
            value='Filter'
            data-wait='Please wait...'
            id=''
            className='filter-input-btn o-button'
          />
        </form>
        <div className='o-form-done'>
          <div>Thank you! Your submission has been received!</div>
        </div>
        <div className='o-form-fail'>
          <div>Oops! Something went wrong while submitting the form.</div>
        </div>
      </div>
    );
  }
}

export default PropertyFilter;
