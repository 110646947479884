import React, { useEffect } from "react";
import "./Login.css";

import LoginForm from '../../components/LoginForm';

import OpicLogo from "../../resources/opic-state-logo.png";
import { Link } from "react-router-dom";
import setErrors from "../../store/actions/setErrors";
import { connect } from "react-redux";

const LoginPage = ({ history, setErrors }) => {
  useEffect(() => {
    document.title = "Authentication | Lagos State Government";

    return () => setErrors([]);
  });
  
  return (
    <div className='container-fluid px-0'>
      <section id='lagos-state-app-authentication-wrapper' className='login'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-4 offset-md-4'>
              <div className='lagos-state-app-authentication-form-wrapper'>
                <div className='col-md-8 offset-md-2 lagos-state-app-img-logo'>
                  <Link to='/'>
                    <img
                      className='lagos-state-app-img-trim img-fluid'
                      src={OpicLogo}
                      alt='Lagos State Government Logo'
                    />
                  </Link>
                </div>

                <p className='lagos-state-app-auth-caption text-center mt-2'>
                  To continue, login with your email address.
                </p>
                <LoginForm history={history} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (_, ownProps) => ({ ...ownProps });

const mapDispatchToProps = (dispatch) => ({
  setErrors(errors) { dispatch(setErrors(errors)); }
});
 
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
