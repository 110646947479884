import React, { useEffect, useState } from 'react';

import '../PropertyList/PropertyList.css';
import DataTable from '../DataTable';
import http, { httpWithoutLoader } from '../../config/axiosConfig';
import AddContractorModal from '../AddContractorPage';


const ContractorList = ({ history }) => {
  const [contractorsJSON, setContractorsJSON] = useState('[]');
  const [contractorTypesJSON, setContractorTypesJSON] = useState('[]');
  const [propertiesJSON, setPropertiesJSON] = useState('[]');

  useEffect(() => {
    (async() => {
      try {
        const [{ data }, { data: typeData }, { data: properties }] = await Promise.all([
          http.get('/admin/all-contractors'),
          httpWithoutLoader.get('/admin/all-contractor-types'),
          httpWithoutLoader.get('/all-pluck-properties')
        ]);
        setContractorsJSON(JSON.stringify(data));
        setContractorTypesJSON(JSON.stringify(typeData));
        setPropertiesJSON(JSON.stringify(properties));
      } catch (error) {}
    })();
  }, [contractorsJSON]);

  const contractorsData = JSON.parse(contractorsJSON);

  const generateActions = (slug) => {
    const viewDetails = () => {};
    const editItem = () => history.push(`/admin/contractors/edit/${slug}`);
    const deleteItem = () => {};

    return { viewDetails, editItem, deleteItem };
  };

  return (
    <div className="property-list-page-wrapper">
      <div className='d-flex justify-content-between'>
        <h2 className='h6 font-weight-bold text-uppercase'>contractors</h2>
        <button
          className="btn btn-primary text-capitalize"
          data-toggle="modal" data-target="#addContractorModal"
          style={{ height: '40px' }}>
            add contractor
        </button>
        
        <AddContractorModal {...{
            types: JSON.parse(contractorTypesJSON),
            properties: JSON.parse(propertiesJSON)
          }}
        />

      </div>
        <DataTable
          {...{
            generateActions,
            data: contractorsData,
            headings: [
              { key: "name", title: 'user name' },
              { key: "email", title: 'email' },
              { key: "phone", title: 'phone' },
              { key: "value", title: 'value', isMoney: true },
              { key: 'created_at', title: 'created' },
              { key: "type", title: 'contractor type', innerKey: 'name' },
              { key: "property", title: 'property name', innerKey: 'property_name' },
            ]
          }}
        />
    </div>
  );
};

export default ContractorList;