import React from "react";
import {Helmet} from "react-helmet";

import FooterPage from "../../components/FooterPage";

import "./SiteMap.css";

import OpicBoardImage from "../../resources/opic-board.jpg";
import AbujaAwardsHousing from "../../resources/abuja-housing-award-sample.jpeg";
import Award from "../../resources/award.png";
import ThisDayAward from "../../resources/thisday-award.jpg";

const styles = {
  BoardImage: {
    backgroundImage: `url(${OpicBoardImage})`,
  },
};

const AboutUsPage = () => {
  return (
    <div className='container-fluid p-0'>
      <Helmet>
        <meta charset='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <title>About Us | OPIC - Expanding the frontiers of Ogun State</title>
        <link rel='canonical' href='https://opicgroup.com/about' />
      </Helmet>
      {/* <HeaderPageHeader /> */}

      <section id='company-profile'>
        <div className='container'>
          <div className='row justify-content-end'>
            <div className='col-lg-6 col-md-9'>
              <div className='company-profile-wrapper'>
                <span>COMPANY PROFILE</span>
                <h4 className='company-profile-title'></h4>
                <p>
                  Ogun State Property &amp; Investment Corporation (OPIC) is a
                  statutory corporation with a focus on the development of
                  residential, commercial and industrial real estate hubs and
                  new cities in Nigeria. OPIC was established by Edict 10 of
                  1984 laws of Ogun State, Nigeria and by its mandate, it is
                  empowered to create new towns and cities across Ogun state and
                  beyond. Our development pipeline of finished and upcoming
                  developments vary across locations and are designed to meet
                  international standards.
                </p>
                <p>
                  As a result of our expertise and robust balance sheet, we have
                  built a diverse portfolio of urban developments in both the
                  residential and commercial sectors in Lagos and Ogun states.
                  Other services included:
                  <ul className='company-profile-list-services'>
                    <li>Development of Residential Communities</li>
                    <li>Development of Commercial and Industrial Hubs</li>
                    <li>Development of Commercial Property Assets</li>
                    <li>Mortgage Advisory/Developer Loans</li>
                    <li>Real Estate Brokerage Services</li>
                    <li>Letting and Leasing Services</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id='about-us-page-who-we-are'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='about-us-page-mission-text'>
                <h2>Mission</h2>
                <p>
                  Our mission is to create model commercial, industrial,
                  residential estates with public facilities and social
                  amenities that will provide an excellent and aesthetic living
                  and/or work environment. By using real estate development as
                  an economic driver, we hope to achieve accelerated growth and
                  urbanization.
                </p>
              </div>
            </div>

            <div className='col-md-6'>
              <div className='about-us-page-vision-text'>
                <h2>Vision</h2>
                <p>
                  As reputable property developers, our vision is to build a
                  diverse portfolio of world-class commercial, industrial and
                  residential assets. Each estate would include a variety of
                  social amenities including but not limited to neighborhood
                  parks, medical clinics, schools, libraries, banks, restaurants
                  and night clubs etc.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id='awards-honorary'>
        <div className='container'>
          <div className='row'>
            <div class='col-12 col-lg-12 col-sm-12'>
              <h2 className='header-text'>Awards And Honorary</h2>
            </div>

            <div className='col-md-12'>
              <div className='row award-honorary-wrapper'>
                <div className='col-md-6 mt-3'>
                  <div className='award-honorary-list'>
                    <div className='award-image-wrapper'>
                      <img
                        src={AbujaAwardsHousing}
                        class='img-fluid'
                        alt='Awardee Logo'
                      />
                    </div>

                    <div className='award-honorary-text-wrapper'>
                      <h4>Winner</h4>
                      <p>
                        Most Innovative Urban developer (2016/2017) - 11th Abuja
                        Housing Show Awards 2017.
                      </p>
                    </div>
                  </div>
                </div>

                <div className='col-md-6 mt-3'>
                  <div className='award-honorary-list'>
                    <div className='award-image-wrapper'>
                      <img
                        src={ThisDayAward}
                        class='img-fluid'
                        alt='Awardee Logo'
                      />
                    </div>

                    <div className='award-honorary-text-wrapper'>
                      <h4>Winner</h4>
                      <p>
                        Most Innovative Urban developer (Public Sector) - 2017
                        BusinessDay Top 25 Most Innovative Companies &amp;
                        Institutions.
                      </p>
                    </div>
                  </div>
                </div>

                <div className='col-md-6 mt-3'>
                  <div className='award-honorary-list'>
                    <div className='award-image-wrapper'>
                      <img
                        src={ThisDayAward}
                        class='img-fluid'
                        alt='Awardee Logo'
                      />
                    </div>

                    <div className='award-honorary-text-wrapper'>
                      <h4>Nominee</h4>
                      <p>
                        Most Innovative Real Estate Developer - 2017 BusinessDay
                        Top 25 Most Innovative Companies &amp; Institutions.
                      </p>
                    </div>
                  </div>
                </div>

                <div className='col-md-6 mt-3'>
                  <div className='award-honorary-list'>
                    <div className='award-image-wrapper'>
                      <img src={Award} class='img-fluid' alt='Awardee Logo' />
                    </div>

                    <div className='award-honorary-text-wrapper'>
                      <h4>Winner</h4>
                      <p>
                        Best State Property Development Institution - Nigerian
                        Urban Development Awards 2014.
                      </p>
                    </div>
                  </div>
                </div>

                <div className='col-md-6 mt-3'>
                  <div className='award-honorary-list'>
                    <div className='award-image-wrapper'>
                      <img src={Award} class='img-fluid' alt='Awardee Logo' />
                    </div>

                    <div className='award-honorary-text-wrapper'>
                      <h4>Selected</h4>
                      <p>
                        Award of Excellence in Public Real Estate - 2014 Fodion
                        Exhibition and Centenary
                      </p>
                    </div>
                  </div>
                </div>

                <div className='col-md-6 mt-3'>
                  <div className='award-honorary-list'>
                    <div className='award-image-wrapper'>
                      <img src={Award} class='img-fluid' alt='Awardee Logo' />
                    </div>

                    <div className='award-honorary-text-wrapper'>
                      <h4>Honored</h4>
                      <p>
                        National Association of Ogun State Student - Meritorious
                        Award 2016 ( Contribution to Ogun State)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id='work-with-us'>
        <div className='container'>
          <div className='row'>
            {/* <div class='col-12 col-lg-12 col-sm-12'>
              <h2 className='header-text text-left'>Working with us</h2>
            </div> */}

            <div class='col-12 col-md-12 col-sm-12'>
              <div className='row'>
                <div className='col-md-5'>
                  <div className='work-with-us-overview'>
                    <h2 className='text-uppercase'>Working with us</h2>
                    {/* <h4>Working with us</h4> */}
                    <p>
                      OPIC is a property and development Corporation with
                      current projects around Southwest Nigeria spanning across
                      Residential, Commercial and Industrial sectors.
                    </p>
                  </div>
                </div>

                <div className='col-md-7'>
                  <div className='work-with-us-objectives'>
                    <h2>Objectives</h2>
                    <p>
                      OPIC typically works with capable contractors and
                      suppliers using a deferred payment terms where payment is
                      within a cycle of development or maximum 90 days
                      thereafter. OPIC is responsible for all aspects of
                      development cycle including:
                      <ul className='company-profile-list-services'>
                        <li>Acquisitions</li>
                        <li>Master Planning</li>
                        <li>Design</li>
                        <li>Construction</li>
                        <li>Marketing</li>
                        <li>Sales</li>
                        <li>Post Completion Management</li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterPage />
    </div>
  );
};

export default AboutUsPage;
