import React, {Component} from "react";
import PropertyCard from "../../modules/sitemaps/PropertyCard";

export default class PropertyList extends Component {
  render() {
    const propertyList =
      this.props.properties.length === 0 ? (
        <p>No entries found</p>
      ) : (
        this.props.properties.map(property => (
          <PropertyCard
            key={property.slug}
            price={property.property_price.split(".")[0]}
            bedroom={property.property_bedrooms}
            bathroom={property.property_bathrooms}
            name={property.property_name}
            city={property.property_city}
            state={property.property_state}
            slug={property.slug}
            propertyImage={property.property_cover_image}
            status={property.property_status}
            type='homepage'
            sqm={property.property_size}
          />
        ))
      );
    return (
      <div id='' className='property-wrapper'>
        {propertyList}
      </div>
    );
  }
}
