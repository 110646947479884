import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import "./index.css";
import "./normalize.css";
import "./base-components.css";
import "./theme.css";
import * as serviceWorker from "./serviceWorker";
import Routes from "./modules";
import store from "./store";
import Spinner from "./components/Spinner";
import ErrorPage from "./components/ErrorPage";

ReactDOM.render(
  <Provider store={store}>
    <ErrorPage>
      <Spinner {...{size: "4rem", color: "brown"}} />
      <Routes />
    </ErrorPage>
  </Provider>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
