import React, { useState } from 'react';

import { validateForm, getInputErrorElement } from '../../utils/formUtils';
import http from '../../config/axiosConfig';
import { invalidEmailErrorMsg, invalidPhoneErrorMsg, invalidAddressErrorMsg } from '../../utils/errorMessageUtils';
import { emailRegexString, phoneRegexString } from '../../utils/regexUtils';
import ErrorNotification from '../ErrorNotification';


const AddBuyerModal = ({ properties }) => {
  const [stateJSON, setStateJSON] = useState(JSON.stringify({
    name: '', email: '', phone: '', address: '', propertyid: ''
  }));

  const state = JSON.parse(stateJSON);

  const handleChange = ({ target: { name, value } }) => setStateJSON(JSON.stringify({ ...state, [name]: value }));

  const handleSubmit = async (event) => {
    const invalidInput = validateForm(event);
    if (invalidInput) return invalidInput.focus();
    try {
      await http.post('/admin/add-buyer', state);
      window.location.reload();
    } catch (error) {}
  };

  return (
    <div
      className="modal" id="addBuyerModal"
      tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <ErrorNotification />
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-capitalize" id="exampleModalLongTitle">add buyer</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit} className='mt-2 px-2' noValidate>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="input-group flex-column">
                      <label className='text-capitalize'>user name</label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="User name"
                        value={state.name}
                        onChange={handleChange}
                        required />
                      {getInputErrorElement()}
                    </div>
                  </div>
                </div>
                
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="input-group flex-column">
                      <label className='text-capitalize'>email</label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="user@example.com"
                        value={state.email}
                        onChange={handleChange}
                        pattern={emailRegexString}
                        required />
                      {getInputErrorElement(invalidEmailErrorMsg)}
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="input-group flex-column">
                      <label className='text-capitalize'>user phone</label>
                      <input
                        type="text"
                        name="phone"
                        className="form-control"
                        placeholder="User phone"
                        value={state.phone}
                        onChange={handleChange}
                        pattern={phoneRegexString}
                        required />
                      {getInputErrorElement(invalidPhoneErrorMsg)}
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="input-group flex-column">
                      <label className='text-capitalize'>address</label>
                      <input
                        type="text"
                        name="address"
                        className="form-control"
                        placeholder="User address"
                        onChange={handleChange}
                        required />
                      {getInputErrorElement(invalidAddressErrorMsg)}
                    </div>
                  </div>
                </div>
                
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="input-group flex-column">
                      <label className='text-capitalize'>property</label>
                      <select
                        name="propertyid"
                        className='form-control'
                        onChange={handleChange}
                        required
                      >
                        <option value="" defaultValue>-- Select a property --</option>
                        {
                          properties.map(({ id, property_name }) => (
                            <option value={id} key={id}>{property_name}</option>
                          ))
                        }
                      </select>
                      {getInputErrorElement('Please select a property')}
                    </div>
                  </div>
                </div>
              </div>

              <div className="buttons d-flex justify-content-between mt-4 mb-2">
                <button type='button' className='text-capitalize btn btn-danger' data-dismiss="modal">cancel</button>
                <button type='submit' className='text-capitalize btn btn-primary'>save buyer</button>
              </div>
            </form>
          </div>
          {/* <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" className="btn btn-primary">Save changes</button>
          </div> */}
        </div>
      </div>
    </div>
  );
}
 
export default AddBuyerModal;