import React from "react";
// import "./Form.css";
import { validateForm } from "../../utils/formUtils";
import { emailRegexString } from "../../utils/regexUtils";
import { Link } from "react-router-dom";
import http from "../../config/axiosConfig";


const ForgetPasswordForm = ({ email, setEmail, setHasSentEmail }) => {
  const handleEmailChange = ({ target: { value } }) => setEmail(value);
  
  const handleSubmit = async (event) => {
    const invalidInput = validateForm(event);
    if (invalidInput) return invalidInput.focus();
    try {
      await http.post('/auth/forgot-password', { email, source: 'opic' });
      setHasSentEmail(true);
    } catch (error) {}
  };
  
  return (
    <div className='lagos-state-app-auth-form'>
      <form noValidate onSubmit={handleSubmit}>
        <div className='form-group row'>
          <div className='col-md-12'>
            <input
              type='email'
              name='email'
              value={email}
              className='form-control'
              placeholder='Email Address'
              onChange={handleEmailChange}
              pattern={emailRegexString}
              required
            />
            <div className="invalid-feedback">Invalid or empty email address</div>
          </div>
        </div>

        <div className='form-group row lagos-state-app-auth-reset-password'>
          <div className='col-md-12'>
            <button
              className='lagos-state-app-auth-reset-password-btn'
              type='submit'
            >
              Reset Password
            </button>

            <p className='lagos-state-app-auth-account-holder'>
              <Link to='/auth/login'>Return to Sign In</Link>
            </p>
          </div>
        </div>
      </form>
    </div>
  );
}
 
export default ForgetPasswordForm;
