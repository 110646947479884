import React from "react";
import {Route, Switch} from "react-router-dom";
import Error404Page from "../../components/Error404Page";
import ErrorNotification from "../../components/ErrorNotification";
import HomePage from "./HomePage";
import FindPropertyPage from "./FindProperty";
import AboutUsPage from "./AboutUsPage";
import SinglePropertyPage from "./SinglePropertyPage";
import HomeFinance from "./HomeFinance";
import HomePurchase from "./HomePurchase";
import BoardMembers from "./BoardMembers";
import ExecutiveManagement from "./ExecutiveManagement";
import AuthRoutes from "../auth";
import HeaderPageHeader from "../../components/HeaderPage/HomePageHeader";
import ContactUsPage from "./ContactUsPage";
import FaqsPage from "./FaqsPage";

const SiteMapRoutes = () => {
  return (
    <div>
      <ErrorNotification />
      <HeaderPageHeader />
      <Switch>
        <Route exact path='/' component={HomePage} />
        <Route exact path='/find-property' component={FindPropertyPage} />
        <Route
          exact
          path='/find-property/:slug'
          component={SinglePropertyPage}
        />
        <Route exact path='/company-profile' component={AboutUsPage} />
        <Route exact path='/home-finance' component={HomeFinance} />
        <Route
          exact
          path='/about-opic/board-members'
          component={BoardMembers}
        />
        <Route
          exact
          path='/about-opic/executive-management'
          component={ExecutiveManagement}
        />
        <Route exact path='/home-purchase' component={HomePurchase} />
        <Route exact path='/contact-us' component={ContactUsPage} />
        <Route exact path='/frequently-asked-questions' component={FaqsPage} />
        <Route component={Error404Page} />
      </Switch>
    </div>
  );
};

export default SiteMapRoutes;
