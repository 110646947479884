import React, { useEffect, useState } from 'react';
import PropertiesStatusOverview from '../PropertiesStatusOverview';
import ByCityValueStats from '../PropertyTotalStats';
import './Dashboard.css';
import http from '../../config/axiosConfig';
import { formatMoneyValue } from '../../utils/stringUtils';


const Dashboard = () => {
  const [statsJSON, setStatJSON] = useState('{}');

  useEffect(() => {
    (async() => {
      try {
        const { data } = await http.get('/admin/dashboard');
        setStatJSON(JSON.stringify(data));
      } catch (error) {}
    })();
  });

  if (statsJSON === '{}') return '';
  const stats = JSON.parse(statsJSON);
  const generalStatsMapper = {
    total_property_count: {
      title: 'total developments', icon: 'icon-home', color: 'text-info'
    },
    total_city: {
      title: 'locations', icon: 'icon-map', color: 'text-warning'
    },
    available_units: {
      title: 'units available', icon: 'icon-check', color: 'text-success', innerKey: 'available_count'
    },
    units_sold: {
      title: 'units sold', icon: 'icon-credit-card', color: 'text-info', innerKey: 'sold_count'
    }
  };


  return (
    <div className="dashboard-wrapper">
      <h2 className='text-capitalize h4'>dashboard</h2>

      <div className="counts-summary-wrapper text-capitalize">
        {
          Object.entries(generalStatsMapper).map(([k, v], index) => {
            return k === 'total_property_count' ? (
              <div className={`item-${index + 1}`} key={index}>
                <div className="card top_counter">
                  <div className="body">
                    <div className={`icon ${v.color}`}><i className={`${v.icon}`}></i> </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className='d-flex justify-content-start align-items-center'>
                        <h3 className="h2 mr-2">{stats[k]}</h3>
                        <div className="label d-flex flex-column justify-content-center">
                          {v.title.split(' ').map((word, index) => <p className='mb-0' key={index}>{word}</p>)}
                        </div>
                      </div>
                      <div className='d-flex flex-column align-items-end'>
                        <div className="label">total value</div>
                        <h5 className="">
                          {
                            (() => {
                              const totalValue = stats.city_value.reduce((acc, { value }) => acc + parseFloat(value), 0);
                              return '₦ ' + formatMoneyValue(totalValue).split('.')[0];
                            })()
                          }
                        </h5>
                      </div>
                    </div>
                  </div>                        
                </div>
              </div>
            ) : (
              <div className={`item-${index + 1}`} key={index}>
                <div className="card top_counter">
                  <div className="body">
                    <div className={`icon ${v.color}`}><i className={`${v.icon}`}></i> </div>
                    <div className="d-flex justify-content-end align-items-center">
                      <div className='d-flex flex-column align-items-end'>
                        <h3 className="mb-0">
                          {
                            (() => {
                              const data = stats[k];
                              return typeof data === 'object' ? data[v.innerKey] : data;
                            })()
                          }
                        </h3>
                        <div className="label">{v.title}</div>
                      </div>
                    </div>
                  </div>                        
                </div>
              </div>
            );
          })
        }
      </div>

      <div className="dashboard-stats row">
        <PropertiesStatusOverview
          {...{
            stats: stats.status_count
          }}
        />
        <ByCityValueStats {...{data: stats.city_value}} />
      </div>
      
      <div className='pr-3 pl-3'>
        <div className="by-cities-wrapper row mt-4 mb-4 pl-3 pr-3">
          {
            stats.city_count.map(({ city_count, property_city }, ind) => {
              return (
                <div className="by-city-wrapper d-flex justify-content-around align-items-center col-xs-12 col-sm-6 col-md-3" key={ind}>
                  <div className="icon text-warning">
                    <i className='icon-map'></i>
                  </div>

                  <div className="details text-left">
                    <div className='mb-2'>{property_city}</div>
                    <h3 className='text-center'>{city_count}</h3>
                  </div>
                </div>
              )
            })
          }
      </div>
      </div>
    </div>
  );
}
 
export default Dashboard;