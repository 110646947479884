import React, { useEffect, useState } from "react";
import DataTable from "../DataTable";
import './PropertyList.css';
import http from "../../config/axiosConfig";
import { normalizeConcatString } from "../../utils/stringUtils";
import { deleteProperty } from "../../utils/dashboardUtils";

const PropertyList = ({ history }) => {
  const [countJSON, setCountJSON] = useState('{}');
  const [propertiesJSON, setPropertiesJSON] = useState('[]');
  useEffect(() => {
    (async() => {
      try {
        const { data, count } = await http.get('/admin/all-properties');
        setCountJSON(JSON.stringify(count));
        setPropertiesJSON(JSON.stringify(data.data));
      } catch (error) {}
    })();
  });

  const generateActions = (slug) => {
    const viewDetails = () => history.push(`/admin/properties/view/${slug}`);
    
    const editItem = () => history.push(`/admin/properties/edit/${slug}`);
    
    const deleteItem = async () => {
      try {
        await deleteProperty(history, slug);
      } catch (error) {}
    };

    return { viewDetails, editItem, deleteItem };
  };

  const propertiesCount = JSON.parse(countJSON);
  const propertiesData = JSON.parse(propertiesJSON);

  return (
    <div className="property-list-page-wrapper">
      <h2 className='h6 font-weight-bold text-uppercase'>properties</h2>
      
      <div className="stat-wrapper card px-3">
        <ul className="list-unstyled d-flex mt-2">
          {
            countJSON === '{}' ? '' : Object.entries(propertiesCount).map(([k, v], index) => {
              return (
                <li className='stat-item mr-4' key={index}>
                  <span className='count d-block h4 text-center'>{ v }</span>
                  <span className="category text-capitalize mt-1">{ normalizeConcatString(k) }</span>
                </li>
              );
            })
          }
        </ul>
      </div>
        <DataTable
          {...{
            generateActions,
            data: propertiesData,
            headings: [
              { key: "property_name", title: 'property name' },
              { key: "property_city", title: 'city' },
              { key: 'created_at', title: 'created' },
              { key: "property_status", title: 'status' }
            ]
          }}
        />
    </div>
  );
  // const wrapperRef = useRef(null);

  // return (
  //   <div ref={wrapperRef} style={{ height: '100vh' }}>
  //     <iframe
  //       title='financeplus'
  //       src='https://www.financeplus.ng'
  //       style={{ width: '100%', height: '100%' }}></iframe>
  //   </div>
  // );
};

export default PropertyList;
