import React, {Component} from "react";
import {removeCommas} from "../../utils/stringUtils";

class PriceSummary extends Component {
  defaultState = {
    rate: 17.5,
    year: 20
  };
  state = {...this.defaultState};

  calculateMonthlyPayment = () => {
    const price = this.props.price || "";
    const principal = Math.round(removeCommas(price));
    const months = Number(this.state.year * 12);
    const rate = parseFloat(this.state.rate / 100 / 12);
    const rate_plus_one = 1 + rate;
    const rate_raise_to_Number_of_month = Math.pow(rate_plus_one, months);
    const numerator = principal * rate * rate_raise_to_Number_of_month;
    const denomirator = rate_raise_to_Number_of_month - 1;
    const monthly_payment = (numerator / denomirator).toFixed(2);
    const payback_value = Math.round(monthly_payment);
    return payback_value;
  };

  render() {
    return (
      <div className='property-price-box'>
        <div className='price-card-inner'>
          <div className='price-from'>Prices from</div>
          <h2 className='property-price'>
            &#8358;
            {new Intl.NumberFormat().format(
              Math.round(removeCommas(this.props.price || ""))
            )}
          </h2>
        </div>
        <div className='separator'></div>

        <div className='price-card-inner'>
          <div className='mortgage-wrapper'>
            <p className='title-doc-heading'>Property title</p>
            <div className='title-doc-icon'></div>
          </div>
          <p className='title-doc'>Certificate Of Occupancy</p>
        </div>

        <div className='property-mortage-highlight mt-3'>
          <div className='price-card-inner'>
            <p className='property-mortgage-heading'>Buy on a mortgage</p>
            <div className='mortgage-wrapper'>
              <p className='property-mortage-value'>
                &#8358;
                {new Intl.NumberFormat().format(this.calculateMonthlyPayment())}
              </p>
              <div className='property-mortgage-freq'>/month</div>
            </div>
          </div>
          <div className='separator'></div>
          <div className='price-card-inner'>
            <p className='property-repayment-heading'>Payback over</p>
            <div className='repayment-wrapper'>
              <p className='property-repayment-period'>20</p>
              <div className='property-repayment-freq'>/Years</div>
            </div>
          </div>
        </div>
        <a id='' href='#' className='side-bar-btn o-button'>
          Buy this property
        </a>
      </div>
    );
  }
}

export default PriceSummary;
