import React from 'react';

import { getDaysPast } from '../../utils/dateUtils';
import { normalizeConcatString, formatMoneyValue } from '../../utils/stringUtils';


const DataTableRow = ({ data, headings, viewDetails, editItem, deleteItem }) => {
  return (
    <tr>
      {headings.map(({ key, innerKey, isMoney }, index) => {
        return (
          <td key={index}>
            {
              (() => {
                let value = data[key];
                if (typeof value === 'object') value = !value ? isMoney ? '0' : '' : value[innerKey];
                else if (typeof value === 'number') value = isMoney ? formatMoneyValue(value) : value.toString();

                return key === 'created_at' ? getDaysPast(value) : normalizeConcatString(value);
              })()
            }
          </td>
        )
      })}

      <td className="actions-column d-flex justify-content-around text-capitalize align-items-center">        
        <button
          className="btn btn-success btn-sm"
          onClick={viewDetails}
        >
          <i className='icon-eye'></i>
        </button>
        <button
          className="btn btn-primary btn-sm"
          onClick={editItem}
        >
          <i className="icon-pencil"></i>
        </button>
        <button
          className="btn btn-danger btn-sm"
          onClick={deleteItem}
        >
          <i className="icon-trash"></i>
        </button>
      </td>
    </tr>
  );
}
 
export default DataTableRow;