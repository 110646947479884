import React, { useEffect } from "react";
import "./ForgotPassword.css";

import ForgotPasswordForm from "./ForgetPasswordForm";
import VerifyCodeForm from "./VerifyCodeForm";

import opicLogo from '../../resources/opic state logo 2.png';

import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import { useState } from "react";


const ForgotPassword = ({ history }) => {
  useEffect(() => {
    document.title = "Recover Password | Lagos State Government";  
  });
  const [hasSentEmail, setHasSentEmail] = useState(false);
  const [email, setEmail] = useState('');

  return (
    <div className='container-fluid px-0'>
      <section
        id='lagos-state-app-authentication-wrapper'
        className='reset-password'
      >
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 offset-md-3'>
              <div className='lagos-state-app-authentication-form-wrapper'>
                <div className='col-md-8 offset-md-2 lagos-state-app-img-logo'>
                  <Link href='/'>
                    <img
                      className='lagos-state-app-img-trim img-fluid'
                      src={opicLogo}
                      alt='Lagos State Government Logo'
                    />
                  </Link>
                </div>

                {
                  hasSentEmail ? (
                    <div className='lagos-state-app-reset-password-text-content'>
                      <div className='lagos-state-app-reset-password-text-wrapper verify'>
                        <Icon.CheckCircle size='60px' />
                        <p className='lagos-state-app-reset-password-caption'>
                          Please verify the secret codes sent to your email
                          address. Codes are valid for 24hrs.
                        </p>
                      </div>

                      <VerifyCodeForm {...{ email, history, setHasSentEmail }} />
                    </div>
                  ) : (
                    <div className='lagos-state-app-reset-password-text-content'>
                      <div className='lagos-state-app-reset-password-text-wrapper'>
                        <Icon.Info size='70px' />
                        <p className='lagos-state-app-reset-password-caption'>
                          Please provide the e-mail address associated with your
                          account. Reset codes will be sent for you to verify.
                        </p>
                      </div>

                      <ForgotPasswordForm {...{ email, setEmail, setHasSentEmail }} />
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
 
export default ForgotPassword;
