import React, {Component} from "react";
import {Link} from "react-router-dom";
import {removeCommas} from "../../utils/stringUtils";

class PropertyCard extends Component {
  defaultState = {
    rate: 17.5,
    year: 20
  };
  state = {...this.defaultState};

  calculateMonthlyPayment = () => {
    const price = this.props.price || "";
    const principal = Math.round(removeCommas(price));
    const months = Number(this.state.year * 12);
    const rate = parseFloat(this.state.rate / 100 / 12);
    const rate_plus_one = 1 + rate;
    const rate_raise_to_Number_of_month = Math.pow(rate_plus_one, months);
    const numerator = principal * rate * rate_raise_to_Number_of_month;
    const denomirator = rate_raise_to_Number_of_month - 1;
    const monthly_payment = (numerator / denomirator).toFixed(2);
    const payback_value = Math.round(monthly_payment);
    return payback_value;
  };

  // calculateMonthlyPayment = () => {
  //   const months = Number(this.state.year * 12);
  //   const rate = parseFloat(this.state.rate / 100 / 12);
  //   const rate_plus_one = 1 + rate;
  //   const rate_raise_to_Number_of_month = Math.pow(rate_plus_one, months);
  //   const numerator =
  //     this.state.principal * rate * rate_raise_to_Number_of_month;
  //   const denomirator = rate_raise_to_Number_of_month - 1;
  //   const monthly_payment = (numerator / denomirator).toFixed(2).split(".")[0];
  //   return monthly_payment;
  // };

  render() {
    let content;
    if (this.props.type === "archive") {
      content = (
        <div
          key='{this.props.key}'
          id={this.props.key}
          className='property-card'
        >
          <div
            className='property-image-holder'
            style={{backgroundImage: `url(${this.props.propertyImage})`}}
          ></div>
          <div className='property-deets'>
            <div className='card-price-holder'>
              <div className='card-property-status'>{this.props.status}</div>
              <h4 className='card-price'>&#8358;{this.props.price}</h4>
            </div>
            <div className='card-name-holder'>
              <h3 className='card-title'>{this.props.name}</h3>
              <div className='property-card-extras'>
                <div className='card-location'>{this.props.city}</div>
              </div>
            </div>
            <div className='card-bed-bath-holder'>
              <div className='card-inner-side'>
                <div className='card-icon'></div>
                <div className='card-bed-bath'>{this.props.bedroom} Beds</div>
              </div>
              <div className='card-inner-divider'></div>
              <div className='card-inner-side'>
                <div className='card-icon'></div>
                <div className='card-bed-bath'>{this.props.bathroom} Baths</div>
              </div>
              <div className='card-inner-divider'></div>
              <div className='card-inner-side'>
                <div className='card-icon'></div>
                <div className='card-bed-bath'>{this.props.sqm} Sqm</div>
              </div>
            </div>
            <div className='mortgage-deets-holder'>
              <div className='card-inner-block'>
                <p className='mortgage-heading'>Buy on a mortgage</p>
                <div className='mortgage-wrapper'>
                  <p className='mortage-value'>
                    &#8358;
                    {new Intl.NumberFormat().format(
                      this.calculateMonthlyPayment()
                    )}
                  </p>
                  <div className='mortgage-freq'>/month</div>
                </div>
              </div>
              <div className='card-inner-divider long'></div>
              <div className='card-inner-block'>
                <p className='repayment-heading'>Payback over</p>
                <div className='repayment-wrapper'>
                  <p className='repayment-period'>20</p>
                  <div className='repayment-freq'>/Years</div>
                </div>
              </div>
            </div>
            <Link
              to={"/find-property/" + this.props.slug}
              className='project-card-cta o-button'
            >
              View Property
            </Link>
          </div>
        </div>
      );
    } else {
      content = (
        <div
          key='{this.props.key}'
          id={this.props.key}
          className='property-card'
        >
          <div
            className='property-image-holder'
            style={{backgroundImage: `url(${this.props.propertyImage})`}}
          ></div>
          <div className='property-deets'>
            <div className='card-price-holder'>
              <div className='card-property-status'>{this.props.status}</div>
              <h4 className='card-price'>&#8358;{this.props.price}</h4>
            </div>
            <div className='card-name-holder'>
              <h3 className='card-title'>{this.props.name}</h3>
              <div className='property-card-extras'>
                <div className='card-location'>{this.props.city}</div>
              </div>
            </div>
            <div className='card-bed-bath-holder'>
              <div className='card-inner-side'>
                <div className='card-icon'></div>
                <div className='card-bed-bath'>{this.props.bedroom} Beds</div>
              </div>
              <div className='card-inner-divider'></div>
              <div className='card-inner-side'>
                <div className='card-icon'></div>
                <div className='card-bed-bath'>{this.props.bathroom} Baths</div>
              </div>
              <div className='card-inner-divider'></div>
              <div className='card-inner-side'>
                <div className='card-icon'></div>
                <div className='card-bed-bath'>{this.props.sqm} Sqm</div>
              </div>
            </div>
            <div className='mortgage-deets-holder'>
              <div className='card-inner-block'>
                <p className='mortgage-heading'>Buy on a mortgage</p>
                <div className='mortgage-wrapper'>
                  <p className='mortage-value'>
                    &#8358;
                    {new Intl.NumberFormat().format(
                      this.calculateMonthlyPayment()
                    )}
                  </p>
                  <div className='mortgage-freq'>/month</div>
                </div>
              </div>
              <div className='card-inner-divider long'></div>
              <div className='card-inner-block'>
                <p className='repayment-heading'>Payback over</p>
                <div className='repayment-wrapper'>
                  <p className='repayment-period'>20</p>
                  <div className='repayment-freq'>/Years</div>
                </div>
              </div>
            </div>
            <Link
              to={"/find-property/" + this.props.slug}
              className='project-card-cta o-button'
            >
              View Property
            </Link>
          </div>
        </div>
      );
    }
    return <div>{content}</div>;
  }
}
export default PropertyCard;
