import React, {Component} from "react";
// import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import axios from "axios";

import FooterPage from "../../components/FooterPage";

import SegmentSlider from "../../modules/sitemaps/SegmentSlider";
import PropertyInfo from "../../modules/sitemaps/PropertyInfo";
import AmenityList from "../../modules/sitemaps/AmenityList";
// import PropertyTypes from "../../modules/sitemaps/PropertyTypes";
import PriceSummary from "../../modules/sitemaps/PriceSummary";
import OutRightPurchase from "../sitemaps/OutRightPurchase";
import InstallmentPlan from "../sitemaps/InstallmentPlan";
import MortgageOption from "../sitemaps/MortgageOption";

import http from "../../config/axiosConfig";

class SinglePropertyPage extends Component {
  defaultState = {
    propertyInfo: [],
    propertyImagesArray: [],
    amenities: [],
    rate: 17.5,
    year: 20,
    outrightPurchaseTab: false,
    installmentPlanTab: false,
    mortgageOptionTab: false,
    priceCardTab: true,
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    typeid: "",
    propertyid: "",
    validationMessage: null,
    color: "red"
  };
  state = {...this.defaultState};

  componentDidMount() {
    this.getPropertyInfo();
  }

  getPropertyInfo = () => {
    try {
      http.get("/property-detail/" + this.props.match.params.slug).then(res => {
        this.setState({
          propertyInfo: res.data,
          amenityArray: res.data.amenity
          //   propertyTypeArray: res.data.propertyprice
        });
      });
    } catch (error) {}
  };

  OutrightPurchaseTabClick = e => {
    e.preventDefault();
    this.setState({
      outrightPurchaseTab: true,
      installmentPlanTab: false,
      mortgageOptionTab: false,
      priceCardTab: true
    });
  };

  InstallmentPlanTabClick = e => {
    e.preventDefault();
    this.setState({
      outrightPurchaseTab: false,
      installmentPlanTab: true,
      mortgageOptionTab: false,
      priceCardTab: true
    });
  };

  MortgageOptionTabClick = e => {
    e.preventDefault();
    this.setState({
      outrightPurchaseTab: false,
      installmentPlanTab: false,
      mortgageOptionTab: true,
      priceCardTab: false
    });
  };

  render() {
    return (
      <div className='container-fluid p-0'>
        <Helmet>
          <meta charset='utf-8' />
          <meta name='viewport' content='width=device-width, initial-scale=1' />
          <title>
            Property Information | OPIC - Expanding the frontiers of Ogun State
          </title>
          <link
            rel='canonical'
            href={
              "https://opicgroup.com/find-property" +
              this.state.propertyInfo.slug
            }
          />
        </Helmet>
        <div
          data-animation='slide'
          data-duration='500'
          data-infinite='1'
          className='property-slider o-slider'
        >
          <SegmentSlider sliders={this.state.propertyInfo.propertyphoto} />
        </div>

        <div className='property-details-wrapper'>
          <div className='property-info-wrapper'>
            <div id='' className='property-info'>
              <PropertyInfo
                name={this.state.propertyInfo.property_name}
                state={this.state.propertyInfo.property_state}
                bedroom={this.state.propertyInfo.property_bedrooms}
                bathroom={this.state.propertyInfo.property_bathrooms}
                sqm={this.state.propertyInfo.property_size}
                status={this.state.propertyInfo.property_status}
              />
              <div className='separator'></div>

              <div className='view-on-map-wrapper'>
                <div className='view-on-map'>
                  <h2 className='view-on-map-icon'></h2>
                  <a href='#' className='view-on-map-link'>
                    View this property on the map
                  </a>
                </div>
              </div>

              <div className='separator'></div>

              <div className='info-section'>
                <div className='description-wrapper'>
                  <h2 className='info-section-title'>Description</h2>
                  <div className='info-description'>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.propertyInfo.property_description
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className='separator'></div>

              {/* <div className='info-section'>
                <div className='description-wrapper'>
                  <h2 className='info-section-title'>Units &amp; Prices</h2>
                  <div className='container'>
                    <PropertyTypes
                      types={this.state.propertyInfo.propertyprice}
                    />
                  </div>
                </div>
              </div>

              <div className='separator'></div> */}

              <div className='info-section'>
                <div className='description-wrapper'>
                  <h2 className='info-section-title'>Amenities</h2>
                  <AmenityList amenities={this.state.amenityArray} />
                </div>
              </div>

              <div className='separator'></div>

              
              <div className='info-section'>
                <div className='description-wrapper'>
                  <h2 className='info-section-title'>Purchase Options</h2>
                  <div className='info-description'>
                    Looking to buy this property? Select your preferred mortgage
                    option below.
                  </div>
                  <div className='purchase-option-wrapper'>
                    <div className='purchase-option-toggle-wrapper'>
                      <a
                        className={
                          this.state.outrightPurchaseTab
                            ? "purchase-option-toggle o-inline-block current"
                            : "purchase-option-toggle o-inline-block"
                        }
                        onClick={this.OutrightPurchaseTabClick}
                      >
                        <div className='purchase-option-icon'></div>
                        <h2 className='option-name'>Outright purchase</h2>
                      </a>
                      <a
                        className={
                          this.state.installmentPlanTab
                            ? "purchase-option-toggle o-inline-block current"
                            : "purchase-option-toggle o-inline-block"
                        }
                        onClick={this.InstallmentPlanTabClick}
                      >
                        <div className='purchase-option-icon'></div>
                        <h2 className='option-name'>Installment plan</h2>
                      </a>
                      <a
                        className={
                          this.state.mortgageOptionTab
                            ? "purchase-option-toggle o-inline-block current"
                            : "purchase-option-toggle o-inline-block"
                        }
                        onClick={this.MortgageOptionTabClick}
                      >
                        <div className='purchase-option-icon'></div>
                        <h2 className='option-name'>Mortgage option</h2>
                      </a>
                    </div>

                    <OutRightPurchase
                      outrightPurchase={this.state.outrightPurchaseTab}
                      price={this.state.propertyInfo.property_price}
                      propertyID={this.state.propertyInfo.id}
                    />

                    <InstallmentPlan
                      installmentPlan={this.state.installmentPlanTab}
                      price={this.state.propertyInfo.property_price}
                      propertyID={this.state.propertyInfo.id}
                    />

                    <MortgageOption
                      mortgageOption={this.state.mortgageOptionTab}
                      price={this.state.propertyInfo.property_price || ""}
                    />
                  </div>
                </div>
              </div>
              {/* <PurchaseProperty
                price={this.state.propertyInfo.property_price}
                propertyID={this.state.propertyInfo.id}
              /> */}
            </div>
            <div
              id=''
              className='price-box-inspection-wrapper'
              style={{
                display: this.state.priceCardTab ? "" : "none"
              }}
            >
              <PriceSummary
                price={this.state.propertyInfo.property_price}
                prop_title={this.state.propertyInfo.property_title_doc}
              />
            </div>
          </div>
        </div>

        <FooterPage />
      </div>
    );
  }
}

export default SinglePropertyPage;
