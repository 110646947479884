import React, { Component, createRef } from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import './AddPropertyPage.css';
import { requireFieldErrorMsg } from '../../utils/errorMessageUtils';
import { validateForm, getInputErrorElement, getReplaceErrorElement } from '../../utils/formUtils';
import http, { generalRequester } from '../../config/axiosConfig';
import PropertyTypeList from '../PropertyTypeList';
import PropertyGalleryFormGroup from '../PropertyGalleryFormGroup';


class AddPropertyPage extends Component {
  state = {
    activeStep: 0, states: [], all_amenities: [], statuses: [], propertyTypes: [],
    property_name: '', property_city: '', stateid: '',
    property_cover_image: null, property_description: '', property_full_address: '',
    property_size: '', property_finance_option: '', slug: null, amenities: [],
    hometype_price: [], photos: [], property_neighborhood: '', development_value: 1000000
  };

  constructor(props) {
    super(props);
    this.buttonRefs = [createRef(null), createRef(null), createRef(null), createRef(null)]; 
  }

  async componentDidMount() {
    const data = await this.checkUrlToUpdate();
    this.setState(data);
  };

  async componentDidUpdate({ location: { pathname } }) {
    if (pathname !== this.props.location.pathname) {
      const data = await this.checkUrlToUpdate();
      this.setState(data);
    }
  }

  checkUrlToUpdate = async () => {
    const [
      { data: all_amenities }, { data: states }, { data: statuses }, { data: propertyTypes }
    ] = await Promise.all([
      generalRequester.get('/amenity-list'), generalRequester.get('/all-states'),
      generalRequester.get('/property-status'), generalRequester.get('/all-properties-types')
    ]);
    let data = { all_amenities, states, statuses, propertyTypes };
    
    const clickActiveStep = () => {
      const timeout = setTimeout(() => {
        if (data.activeStep !== 0) this.buttonRefs[data.activeStep].current.click();
        clearTimeout(timeout);
      }, 200);
    };

    const { match: { params: { slug } }, location: { pathname } } = this.props;
    if (slug) {
      const { data: propertyData } = await http.get(`admin/property-detail/${slug}`);
      if (pathname.includes('/admin/properties/add')) {
        data.activeStep = 1;
        clickActiveStep();
      } else data = { ...data, ...propertyData };
    } else {
      if (!this.state.activeStep) {
        data.activeStep = 0;
        clickActiveStep();
      }
    }

    return data;
  };

  handleNextClick = async ({ target, preventDefault }) => {
    const invalidInput = validateForm({ target: target.closest('.card-body'), preventDefault });
    if (invalidInput) return invalidInput.focus();
    const { activeStep } = this.state;
    const { history, match: { params: { slug: urlSlug } } } = this.props;
    try {
      let slug = await this.postPropertyData();
      if (activeStep === 3) {
        return history.replace('/admin/properties');
      } else if (activeStep === 0 && !urlSlug) {
        return history.replace(`/admin/properties/add/${slug}`);
      }
      this.buttonRefs[this.state.activeStep + 1].current.click();
      const data = { activeStep: this.state.activeStep + 1 };
      if (slug) data.slug = slug;
      this.setState(data);
    } catch (error) {}
  };

  handleBackClick = () => {
    this.buttonRefs[this.state.activeStep - 1].current.click();
    this.setState({ activeStep: this.state.activeStep - 1 });
  };
  

  handleChange = ({ target: { name, value } }) => this.setState({ [name]: value });
  
  setHomeTypePrice = (hometype_price) => this.setState({ hometype_price });

  postPropertyData = async () => {
    let { slug, activeStep: step } = this.state;
    const { match: { params: { slug: urlSlug } } } = this.props;
    if (urlSlug || step) step += 1;
    let payload = {...this.state};
    if (step === 4) {
      const { property_cover_image, photos } = this.state;
      payload = { property_cover_image, photos };
    }

    const { data } = await http.post(`/admin/add-property${slug && step ? `/${step}/${slug}` : '/0'}`, payload);
    return data.slug;
  };

  handleSubmit = (event) => {
    const invalidInput = validateForm(event);
    if (invalidInput) return;
  };

  render() {
    const [
      property_info_button, property_desc_button, property_type_button, property_gallery_button
    ] = this.buttonRefs;
    const { states, all_amenities, statuses, propertyTypes } = this.state;

    return (
      <form noValidate onSubmit={this.handleSubmit}>
        <div id="accordion" className='add-property-page-wrapper'>
          <div className="card mb-0">
            <div className="card-header" id="headingOne">
              <h5 className="mb-0 text-left text-capitalize">
                property information
                <button
                  type='button'
                  ref={property_info_button}
                  className="btn"
                  data-toggle="collapse" data-target="#collapseOne"
                  aria-expanded="true" aria-controls="collapseOne"></button>
              </h5>
            </div>
  
            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
              <div className="card-body row form-group">
                <div className="col-sm-12 col-md-4 mb-2 mt-1">
                  <div className="input-group d-flex flex-column">
                    <label className='text-capitalize'>property name</label>
                    <input
                      type="text"
                      name='property_name'
                      className='form-control'
                      value={this.state.property_name || ''}
                      onChange={this.handleChange}
                      required
                    />
                    {getInputErrorElement()}
                  </div>
                </div>
                <div className="col-sm-12 col-md-4 mb-2 mt-1">
                  <div className="input-group d-flex flex-column">
                    <label className='text-capitalize'>property size</label>
                    <input
                      type="text"
                      name='property_size'
                      className='form-control'
                      value={this.state.property_size || ''}
                      onChange={this.handleChange}
                      required
                    />
                    {getInputErrorElement()}
                  </div>
                </div>
                <div className="col-sm-12 col-md-4 mb-2 mt-1">
                  <div className="input-group d-flex flex-column">
                    <label className='text-capitalize'>property location</label>
                    <select
                      className='form-control'
                      name='stateid'
                      value={this.state.stateid || ''}
                      onChange={this.handleChange}
                      required
                    >
                      <option value="" defaultValue>-- Select a state --</option>
                      {
                        states.map(({ id, name }) => (
                          <option key={id} value={id}>{name}</option>
                        ))
                      }
                    </select>
                    {getInputErrorElement()}
                  </div>
                </div>
                <div className="col-sm-12 col-md-4 mb-2 mt-1">
                  <div className="input-group d-flex flex-column">
                    <label className='text-capitalize'>property city</label>
                    <input
                      type="text"
                      name='property_city'
                      className='form-control'
                      value={this.state.property_city || ''}
                      onChange={this.handleChange}
                      required
                    />
                    {getInputErrorElement()}
                  </div>
                </div>
                <div className="col-sm-12 col-md-4 mb-2 mt-1">
                  <div className="input-group d-flex flex-column">
                    <label className='text-capitalize'>property full address</label>
                    <input
                      type="text"
                      name='property_full_address'
                      className='form-control'
                      value={this.state.property_full_address || ''}
                      onChange={this.handleChange}
                      required
                    />
                    {getInputErrorElement()}
                  </div>
                </div>
  
                <div className="col-sm-12 d-flex justify-content-end mt-2">
                  <button
                    type='button' className="next-btn btn text-capitalize btn-primary"
                    onClick={this.handleNextClick}
                  >next</button>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-0">
            <div className="card-header" id="headingTwo">
              <h5 className="mb-0 text-left text-capitalize">
                description &amp; amenities
                <button
                  type='button'
                  ref={property_desc_button}
                  className="btn collapsed"
                  data-toggle="collapse" data-target="#collapseTwo"
                  aria-expanded="false" aria-controls="collapseTwo">
                </button>
              </h5>
            </div>
            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
              <div className="card-body row">
                <div className="col-sm-12 mb-2 mt-1">
                  <div className="input-group d-flex flex-column">
                    <label className='text-capitalize'>property decription</label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={this.state.property_description || ''}
                      onChange={(_, editor) => {
                        const property_description = editor.getData();
                        this.setState({ property_description });
                      }}
                    />
                    {
                      this.state.property_description ? '' : getReplaceErrorElement(requireFieldErrorMsg)
                    }
                  </div>
                </div>
                <div className="col-sm-12 mb-2 mt-1">
                  <div className="input-group d-flex flex-column">
                    <label className='text-capitalize'>property amenities</label>
                    <div className="amenities-checks-wrapper row">
                      {
                        all_amenities.map(({ id, name }) => {
                          return (
                            <div className="amenity-checkbox col-sm-2 col-md-4" key={id}>
                              <input
                                type="checkbox"
                                value={id}
                                name="amenities"
                                className='mr-1'
                                onChange={({ target: { checked } }) => {
                                  const { amenities } = this.state;
                                  if (checked) amenities.push(id);
                                  else {
                                    const index = amenities.findIndex(el => el === id);
                                    amenities.splice(index, 1);
                                  }
                                  this.setState({ amenities });
                                }}
                              />
                              <label className='font-weight-light'>{name}</label>
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                </div>
  
                <div className="col-sm-12 d-flex justify-content-between mt-2">
                  <button
                    type='button' className="prev-btn btn text-capitalize btn-primary"
                    onClick={this.handleBackClick}
                  >back</button>
                  <button
                    type='button' className="next-btn btn text-capitalize btn-primary"
                    onClick={this.handleNextClick}
                  >next</button>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-0">
            <div className="card-header" id="headingThree">
              <h5 className="mb-0 text-left text-capitalize">
                property type
                <button
                  type='button'
                  ref={property_type_button}
                  className="btn collapsed"
                  data-toggle="collapse" data-target="#collapseThree"
                  aria-expanded="false" aria-controls="collapseThree">
                </button>
              </h5>
            </div>
            <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
              <div className="card-body row">
                <div className="col-sm-12 col-md-6 mb-2 mt-1">
                  <div className="input-group d-flex flex-column">
                    <label className='text-capitalize'>development value</label>
                    <input
                      type="text"
                      name='development_value'
                      className='form-control'
                      value={this.state.development_value || ''}
                      onChange={this.handleChange}
                      required
                    />
                    {getInputErrorElement()}
                  </div>
                </div>
                <div className="col-sm-12 mb-2 mt-1">
                  <div className="col-sm-12 col-md-6 d-flex justify-content-between mt-3 mb-5 pr-0 pl-0">
                      <p className='d-flex justify-content-start align-items-center mb-0'>
                        Please enter the details of each property in the estate
                      </p>
                      <button
                        type='button'
                        className='btn btn-primary font-weight-bolder'
                        onClick={() => {
                        this.setHomeTypePrice([...this.state.hometype_price, {}])
                      }}
                    >+</button>
                  </div>
                  
                  <PropertyTypeList
                    {...{
                      statuses,
                      propertyTypes,
                      is_estate: this.state.is_estate,
                      setHomeTypePriceInParent: this.setHomeTypePrice,
                      hometype_price: this.state.hometype_price
                    }}
                  />
                <div className="col-sm-12 d-flex justify-content-between mt-2">
                  <button
                    type='button' className="prev-btn btn text-capitalize btn-primary"
                    onClick={this.handleBackClick}
                  >back</button>
                  <button
                    type='button' className="next-btn btn text-capitalize btn-primary"
                    onClick={this.handleNextClick}
                  >next</button>
                </div>
                </div>
  
              </div>
            </div>
          </div>
          <div className="card mb-0">
            <div className="card-header" id="headingFour">
              <h5 className="mb-0 text-left text-capitalize">
                property gallery
                <button
                  type='button'
                  ref={property_gallery_button}
                  className="btn collapsed"
                  data-toggle="collapse" data-target="#collapseFour"
                  aria-expanded="false" aria-controls="collapseThree">
                </button>
              </h5>
            </div>
            <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
              <div className="card-body">
                <PropertyGalleryFormGroup
                  {...{
                    photos: this.state.photos,
                    setPhotos: (photos) => this.setState({ photos }),
                    property_cover_image: this.state.property_cover_image,
                    setPropertyCoverImage: (property_cover_image) => this.setState({ property_cover_image })
                  }}
                />
  
                <div className="col-sm-12 d-flex justify-content-between mt-2">
                  <button
                    type='button' className="prev-btn btn text-capitalize btn-primary"
                    onClick={this.handleBackClick}
                  >back</button>
                  <button
                    type='button' className="next-btn btn text-capitalize btn-primary"
                    onClick={this.handleNextClick}
                  >complete</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}
 
export default AddPropertyPage;