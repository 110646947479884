import React, { useState } from 'react';
import { Eye, EyeOff } from 'react-feather';

import { getInputErrorElement, validateForm } from '../../utils/formUtils';
import { invalidPasswordErrorMsg } from '../../utils/errorMessageUtils';
import BrowserStorage from '../../utils/browserStorageUtils';
import http from '../../config/axiosConfig';
import '../LoginForm/Form.css';


const ProfilePasswordForm = () => {
  const [password, setPassword] = useState('');
  const [confirm_password, setConfirmPassword] = useState('');
  const [hidden, setHidden] = useState(true);
  const toggleShow = () => setHidden(!hidden);

  const handleSubmit = async (event) => {
    const invalidInput = validateForm(event);
    if (invalidInput) return invalidInput.focus();
    try {
      const { slug } = BrowserStorage.getUserData();
      const data = await http.post(`/auth/change-forgot-password/${slug}`, { password });
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form
      noValidate
      onSubmit={handleSubmit}
      className="password-form col-sm-12 col-md-6 my-3"
    >
      <fieldset className="form-group">
        <legend className='text-capitalize'>password</legend>
        <div className="input-group">
          <input
            type={hidden ? "password" : "text"}
            name='password'
            value={password}
            onChange={({ target: { value } }) => setPassword(value)}
            className='form-control'
            minLength='6'
            placeholder='Password'
            autoComplete='current-password'
            required
          />
          <div className='password-show-hide'>
            {hidden ? (
              <Eye onClick={toggleShow} size='20px' />
            ) : (
              <EyeOff onClick={toggleShow} size='20px' />
            )}
        </div>
        {getInputErrorElement(invalidPasswordErrorMsg)}
        </div>
      </fieldset>
      <fieldset className="form-group">
        <legend className='text-capitalize'>confirm password</legend>
        <div className="input-group">
          <input
            type={hidden ? "password" : "text"}
            name='confirm_password'
            value={confirm_password}
            onChange={({ target: { value } }) => setConfirmPassword(value)}
            className='form-control'
            minLength='6'
            placeholder='Password'
            autoComplete='current-password'
            pattern={password}
            required
          />
          <div className='password-show-hide'>
            {hidden ? (
              <Eye onClick={toggleShow} size='20px' />
            ) : (
              <EyeOff onClick={toggleShow} size='20px' />
            )}
        </div>
        {getInputErrorElement('Value is required and must match with password')}
        </div>
      </fieldset>

      <div className="form-group">
        <button type='submit' className='btn btn-primary text-uppercase'>change password</button>
      </div>
    </form>
  );
}
 
export default ProfilePasswordForm;