import React from "react";
import { Settings } from 'react-feather';
import {Link} from "react-router-dom";

const Sidebar = () => {
  return (
    <div id='left-sidebar' className='sidebar' style={{borderRight: '1px solid #eee'}}>
      <div className='sidebar-scroll'>
        {/* <div className='user-account'>
          <img
            src='https://via.placeholder.com/300.png'
            className='rounded-circle user-photo'
            alt=''
          />
          <div className='dropdown'>
            <span>Welcome,</span>
            <Link to='' className='user-name'>
              <strong>Webmaster</strong>
            </Link>
          </div>
          <hr />
        </div> */}
        {/* <ul className='nav nav-tabs'> */}
        <ul className='nav mt-5'>
          <li className='nav-item'>
            <a className='nav-link active text-black-50' data-toggle='tab' href='#menu'>
              Menu
            </a>
          </li>
        </ul>

        <div className='tab-content p-l-0 p-r-0'>
          <div className='tab-pane active' id='menu'>
            <nav id='left-sidebar-nav' className='sidebar-nav'>
              <ul id='main-menu' className='metismenu text-capitalize'>
                <li>
                  <Link to='/admin'>
                    <i className='icon-home'></i> <span>dashboard</span>
                  </Link>
                </li>
                <li>
                  <a className='has-arrow' to=''>
                    <i className='icon-grid'></i> <span>properties</span>
                  </a>
                  <ul aria-expanded='false' className='collapse'>
                    <li>
                      <Link to='/admin/properties'>properties list</Link>
                    </li>
                    <li>
                      <Link to='/admin/properties/add'>new property</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to='/admin/users'>
                    <i className='icon-user'></i> <span>users</span>
                  </Link>
                </li>
                <li>
                  <Link to='/admin/contractors'>
                    <i className='icon-briefcase'></i> <span>contractors</span>
                  </Link>
                </li>
                <li>
                  <Link to='/admin/buyers'>
                    <i className='icon-users'></i> <span>buyers</span>
                  </Link>
                </li>
                <li>
                  <Link to='/admin/profile' className='d-flex align-items-center'>
                    <Settings size={17} /> <span style={{ marginLeft: '25px' }}>profile</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
