import Axios from "axios";
import store from '../store';
import setLoading from "../store/actions/setLoading";
import setErrors from '../store/actions/setErrors';
import { BASE_URL as baseURL, GENERAL_API_BASE_URL } from '../utils/constants';
import BrowserStorage from "../utils/browserStorageUtils";


const http = Axios.create({
  baseURL,
  headers: {
    Authorization: `Bearer ${BrowserStorage.getUserToken()}`
  }
});

http.interceptors.request.use((config) => {
  store.dispatch(setLoading(true));
  return config;
});

http.interceptors.response.use((response) => {
  store.dispatch(setLoading(false));
  return response.data;
},
(error) => {
  store.dispatch(setLoading(false));
  const { response: { data: { data: errorData } } } = error;
  store.dispatch(setErrors(errorData));
  return Promise.reject(error.response);
});


let http2 = Axios.create({ baseURL: GENERAL_API_BASE_URL });

http2.interceptors.request.use((config) => {
  store.dispatch(setLoading(true));
  return config;
});

http2.interceptors.response.use((response) => {
  store.dispatch(setLoading(false));
  return response.data;
},
(error) => {
  store.dispatch(setLoading(false));
  const { response: { data: { data: errorData } } } = error;
  store.dispatch(setErrors(errorData));
  return Promise.reject(error.response);
});

const http3 = Axios.create({
  baseURL,
  headers: {
    Authorization: `Bearer ${BrowserStorage.getUserToken()}`
  }
});

http3.interceptors.response.use((response) => {
  return response.data;
},
(error) => {
  const { response: { data: { data: errorData } } } = error;
  store.dispatch(setErrors(errorData));
});


export const generalRequester = http2;
export const httpWithoutLoader = http3;
export default http;